.footer
{
	display: block;
	background: #1f2128;
	padding-top: 48px;
	padding-bottom: 48px;
}

@media only screen and (max-width: 991px)
{
	.footer_content_row > div[class^='col']:not(:last-child)
	{
		margin-bottom: 80px;
	}
}