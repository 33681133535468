@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
4. Menu
5. Home
6. Sidebar
7. Episode
8. Footer


******************************/

/***********
1. Fonts
***********/

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Poppins:100,300,400,500,600,700,800,900');

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background: #FFFFFF;
	color: #a5a5a5;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 1.875;
	font-weight: 300;
	color: rgba(0,0,0,0.5);
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #ffa07f;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FFFFFF;
	background: #ffa07f;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: #ff3500;
	color: #FFFFFF;
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
	line-height: 1.2;
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
img
{
	max-width: 100%;
}
button:active
{
	outline: none;
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.parallax_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.background_image
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.nopadding
{
	padding: 0px !important;
}
.owl-carousel,
.owl-carousel .owl-stage-outer,
.owl-carousel .owl-stage,
.owl-carousel .owl-item
{
	height: 100%;
}

.page_nav_row
{
	margin-top: 100px;
}
.page_nav ul li
{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: solid 2px #96979b;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.page_nav ul li:not(:last-of-type)
{
	margin-right: 8px;
}

.page_nav ul li button
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 46px;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	color: #96979b;
}
.page_nav ul li button.thumbsDown{
	line-height: 50px;
}
.page_nav ul li:hover,
.page_nav ul li.active
{
	background: #ff3500;
	border-color: #ff3500;
}
.page_nav ul li.active button,
.page_nav ul li:hover button
{
	color: #FFFFFF;
}
.page_nav ul li:last-child a i
{
	font-size: 20px;
	line-height: 27px;
	-webkit-transform: translateX(2px);
	-moz-transform: translateX(2px);
	-ms-transform: translateX(2px);
	-o-transform: translateX(2px);
	transform: translateX(2px);
}

.page_nav ul p
{
	display: block;
	margin-left: 10px;
	margin-right: 10px;
	line-height: 46px;
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	color: #96979b;
}
.slide
{
	height: 100%;
}
.button_border
{
	display: inline-block;
	height: 42px;
	border: solid 1px #FFFFFF;
	border-radius: 21px;
	background: transparent;
}
.button_border a
{
	display: block;
	position: relative;
	height: 100%;
	padding-left: 28px;
	padding-right: 31px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 40px;
	border-radius: 21px;
}
.button_border:hover
{
	background: #FFFFFF;
}
.button_border:hover a
{
	color: #ff3500;
}
.button_fill
{
	display: inline-block;
	height: 42px;
	background: #ff3500;
	border-radius: 21px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.button_fill:hover
{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
}
.button_fill a
{
	display: block;
	width: 100%;
	height: 100%;
	padding-left: 25px;
	padding-right: 25px;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
}
.section_title
{
	font-size: 24px;
	color: #2e3038;
	font-weight: 500;
}
.tags
{
	position: absolute;
	top: 13px;
	left: 11px;
}
.tags ul li
{
    display: inline-block;
    height: 20px;
    background: #ff3500;
    border-radius: 3px;
    margin-bottom: 5px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.tags ul li:not(:last-of-type)
{
    margin-right: 10px;
}
.tags ul li:hover
{
	background: #2e3038;
}
.tags ul li a
{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 12.73px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: 500;
    padding-left: 6px;
    padding-right: 7px;
}
div.top-loading-bar
{
	z-index:9999999;
	position:fixed;
	top:0;
	left:0;
	height:2px;
	width:100%
}

/*********************************
3. Header
*********************************/

.header
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	z-index: 100;
}
.header.scrolled
{
	background: rgba(0,0,0,0.63);
}
.header_content
{
	width: 100%;
	height: 86px;
}
.header.scrolled .header_content
{
	height: 70px;
}
.logo
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 33px;
	height: 51px;
	z-index: 1;
}
.logo a
{
	display: block;
	width: 100%;
	height: 100%;
	font-size: 30px;
	font-weight: 500;
	color: #FFFFFF;
}
.logo a span
{
	font-weight: 100;
	margin-right: 5px;
}
.logo a img
{
	vertical-align: top;
	margin-left: 5px;
}
.main_nav ul li:not(:last-of-type)
{
	margin-right: 40px;
}
.main_nav ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
}
.main_nav ul li a:hover
{
	color: #ff3000;
}
.main_nav ul li a.active
{
	color: #ff3000;
}
.header_right
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 27px;
}
.submit
{
	height: 32px;
	border-radius: 16px;
	margin-right: 19px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.submit a
{
	display: block;
	height: 100%;
	line-height: 30px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	padding-left: 20px;
	padding-right: 20px;
}
.submit:hover
{
	border-color: #ff3000;
}
.submit:hover a
{
	color: #ff3000;
}
.hamburger
{
	display: none;
	margin-left: 20px;
	cursor: pointer;
}
.hamburger i
{
	font-size: 28px;
	color: #FFFFFF;
}

/*********************************
4. Menu
*********************************/

.menu
{
	position: fixed;
	left: 0;
	top: -100vh;
	width: 100vw;
	height: 100vh;
	padding-right: 70px;
	padding-top: 200px;
	background: #1f2128;
	z-index: 99;
	-webkit-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-moz-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-ms-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-o-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	transition: all 800ms cubic-bezier(.88,.31,.65,.91);
}
.menu.active
{
	top: 0;
}
.menu_content
{
	width: 100%;
	height: 100%;
}
.menu_nav_list li
{
	-webkit-transform: translateY(-50px);
	-moz-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	-o-transform: translateY(-50px);
	transform: translateY(-50px);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}
.menu_nav_list li:first-child
{
	transition-delay: 400ms;
}
.menu_nav_list li:nth-child(2)
{
	transition-delay: 500ms;
}
.menu_nav_list li:nth-child(3)
{
	transition-delay: 600ms;
}
.menu_nav_list li:nth-child(4)
{
	transition-delay: 700ms;
}
.menu_nav_list li:nth-child(5)
{
	transition-delay: 800ms;
}
.menu_nav_list li:nth-child(6)
{
	transition-delay: 900ms;
}
.menu_nav_list li:nth-child(7)
{
	transition-delay: 1000ms;
}
.menu_nav_list li:nth-child(8)
{
	transition-delay: 1100ms;
}
.menu_nav_list li:nth-child(9)
{
	transition-delay: 1200ms;
}
.menu.active .menu_nav_list li
{
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
	visibility: visible;
	opacity: 1;
}
.menu_nav_list li a
{
	position: relative;
	font-size: 40px;
	color: #FFFFFF;
	font-weight: 400;
	line-height: 1.3;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.menu_nav_list li a:hover
{
	color: #ff3500;
}
.menu_nav_list li a.active
{
	color: #ff3500;
}
.menu_extra
{
	position: absolute;
	left: 0;
	bottom: 15px;
	width: 100%;
}
.menu_submit
{
	display: none;
	margin-bottom: 20px;
}
.menu_submit a
{
	white-space: nowrap;
	line-height: 1.1;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_submit a:hover
{
	color: #ff3500;
}
.menu .social
{
	display: none;
}
.menu .social ul li:not(:first-child)
{
	margin-left: 8px;
}

/*********************************
5. Home
*********************************/

.home
{
	height: 490px;
}
.home_container
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 171px;
}
.home_content
{
	
}
.home_title h1
{
	font-size: 36px;
	color: #FFFFFF;
	font-weight: 700;
}
.home_subtitle
{
	font-size: 18px;
	font-weight: 300;
	color: #FFFFFF;
	margin-top: 12px;
}
.home_player_container
{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding-bottom: 27px;
}
.player
{
	width: 100%;
	border-radius: 3px;
	overflow: hidden;
}
.player_content
{
	width: 100%;
	padding-left: 12px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.single_player_container
{
	
}
.single_player
{
	width: 100%;
	height: 40px;
}
.show_info
{
	margin-top: 29px;
}
.show_fav
{
	margin-left: -3px;
}
.show_fav_icon
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.show_comments
{
	margin-left: 17px;
}
.show_comments a
{
	display: block;
}
.show_comments_icon
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.show_info_icon img
{
	max-height: 100%;
}
.show_info_icon svg
{
	max-width: 100%;
	max-height: 100%;
}
.svg path, .svg rect, .svg polygon
{
    fill: #7b7b7b;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.show_fav_icon.active .svg path,
.show_fav_icon:hover .svg path,
.show_comments:hover .svg path
{
	fill: #ff3500;
}
.show_fav_count,
.show_comments_count
{
	font-size: 16px;
	font-weight: 500;
	color: #5e5f62;
	margin-left: 7px;
	margin-bottom: 6px;
}
.show_comments:hover .show_comments_count
{
	color: #ff3500;
}

/*********************************
6. Sidebar
*********************************/

.sidebar
{
	width: 100%;
	z-index: 1;
	margin-top: 53px;
}
.sidebar_title
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.episode_image_container
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.episode_image
{
	width: 100%;
	max-width: 300px;
	border-radius: 3px;
	overflow: hidden;
	margin-top: -146px;
}
.sidebar_list
{
	
}
.sidebar_list ul
{
	margin-top: 19px;
}
.sidebar_list ul li:not(:last-of-type)
{
	margin-bottom: 3px;
}
.sidebar_list ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #5e5f62;
}
.sidebar_list ul li a:hover
{
	color: #ff3500;
}
.sidebar_tags
{
	margin-top: 38px;
}
.sidebar .tags
{
	position: relative;
	top: auto;
	left: auto;
	margin-top: 24px;
}
.sidebar_archive
{
	margin-top: 30px;
}
.dropdown
{
	width: 100%;
	background: #f7f7f7;
	border-radius: 3px;
	margin-top: 24px;
}
.dropdown_selected
{
	position: relative;
	width: 100%;
	height: 32px;
	cursor: pointer;
	padding-left: 10px;
	padding-right: 15px;
	font-size: 14px;
	font-weight: 400;
	color: #2e3038;
}
.dropdown_selected i
{
	font-size: 12px;
	color: #2e3038;
}
.dropdown > ul > li > ul
{
	position: absolute;
	right: 0;
	top: 100%;
	width: 100%;
	padding-top: 15px;
	background: #FFFFFF;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.dropdown > ul > li > ul li
{
	padding-left: 10px;
	padding-right: 15px;
	padding-bottom: 7px;
}
.dropdown > ul > li > ul li:not(:last-of-type)
{
	margin-bottom: 8px;
	border-bottom: solid 1px rgba(0,0,0,0.05);
}
.dropdown > ul > li > ul li a
{
	display: block;
	font-size: 14px;
	font-weight: 400;
	color: #2e3038;
}
.dropdown > ul > li > ul li a:hover
{
	color: #ff3500;
}
.dropdown_selected:hover > ul
{
	visibility: visible;
	opacity: 1;
}

/*********************************
7. Episode
*********************************/

.episode_container
{
	background: #FFFFFF;
	padding-top: 89px;
	padding-bottom: 99px;
}
.intro_title
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
}
.intro_text
{
	margin-top: 28px;
}
.guests
{
	margin-top: 53px;
}
.guests_container
{
	margin-top: 40px;
}

.songNotAvailable{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top:90px; 
}

.songNotAvailable_Image{
    width: 50%;
	height: auto;
	text-align: center;
}

.songNotAvailable_Text{
    color: rgb(0, 0, 0);
    font-size: 35px;
    margin-top: 20px;
	text-align: center;
}

.AgreeOrDisagree{
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.AgreeOrDisagree > div{
	margin-top: 10px;
	margin-bottom: 10px;
}

.Loading{
	text-align: center;
}

.Loading img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}
