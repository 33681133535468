.content{
	height: 800px;
	background-color: #4f518489; 
}


.ManageUser{
}


.home
{
	background-color: #4a4aff68; 
	height: 346px;
}
.home_container
{
	width: 100%;
	height: 100%;
}
.home_title
{
	
}
.home_title h1
{
	font-size: 5vw;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}

.table{
	height: 500px;
}

.block_button{
	background-color: rgb(255, 0, 0);
	padding: 10px;
	color: #fff;
	border-radius: 10px;
}

.block_button:hover{
	transition: 0.5s;
	background-color: rgba(255, 0, 0, 0.548);
}

.unblock_button{
	background-color: rgb(0, 47, 255);
	padding: 10px;
	color: #fff;
	border-radius: 10px;
	margin-right: 10px;
}

.unblock_button:hover{
	transition: 0.5s;
	background-color: rgba(0, 38, 255, 0.221);
}

.blockeduser{
	display: flex;
}


/*SearchBar*/
#SearchBar{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
#SearchBar .form{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 80vw;
    height: 72px;
    border-radius: 10px;
    padding: 20px;
}

#SearchBar .form input, button{
    outline: none;
    border: none;
    background-color: transparent;
}

#SearchBar .form input{
    width: 100%;
}

#SearchBar.active .form{
    border-radius: 0px;
}

#SearchBar .filter select{
    width: 80px;
}

#searchresults{
    background-color: #fff;
    width: 80vw;
    max-height: 35vh;
    overflow-y: auto;
    overflow-x:hidden;
}

#searchresults > div{
    border-bottom: 1px solid rgb(216, 216, 216);
    padding: 10px;
    cursor: pointer;
    display: flex;
}

#searchresults > div .imageResult{
    margin-right: 10px;
}

#searchresults > div:hover{
    background-color: rgb(216, 216, 216);
    transition: 0.5s;
}


#searchresults.noactive{
    visibility: hidden;
}

.isAlreadyClassified a, .classifyButton button{
    color: #fff;
    background-color: #ff3000;
    padding: 5px;
    text-decoration: underline;
    border-radius: 10px;
    margin-top: 10px;
}

.classifyButton button{
    text-decoration: none;
}
.isAlreadyClassified a:hover, .classifyButton button:hover{
    background-color: #ff6341;
}

.isAlreadyClassified p{
    color: black;
    font-weight: bold;
}

.radio{
	display: flex;
	margin-top: 10px;
}

.radio label{
	color: #111;
	font-weight: bold;
	margin-right: 5px;
}

.LogOutButton{
	background-color: #ff0000;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.LogOutButton:hover{
	background-color: #2e3038;
	transition: 0.2s;
}

.CancelLogOutButton{
	background-color: #bababa;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.CancelLogOutButton:hover{
	background-color: #a8a8a8;
}