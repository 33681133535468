.home
{
	height: 346px;
}
.home_container
{
	width: 100%;
	height: 100%;
}
.home_title
{
	
}
.home_title h1
{
	font-size: 60px;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
}

/*********************************
6. Blog
*********************************/

.blog
{
	background: #FFFFFF;
	padding-top: 109px;
	padding-bottom: 106px;
}
.blog_post:not(:last-child)
{
	margin-bottom: 77px;
}
.blog_post_image
{
	width: 42.5%;
	-webkit-flex: 0 0 auto;
	flex: 0 0 auto;
}
.blog_post_image iframe
{
	vertical-align: middle;
	max-width: 100%;
}
.blog_post_content
{
	width: 57.5%;
	-webkit-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-left: 30px;
}
.blog_post_date
{
	position: absolute;
	left: -6px;
	top: -9px;
	width: 161px;
	height: 42px;
	border-radius: 21px;
	background: #ff3500;
}
.blog_post_date a
{
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
}
.blog_post_title
{
	margin-top: -4px;
}

.blog_post_title a{
	color: #111;
}

.blog_post_title a:hover{
	color: #ff3500;
}


.blog_post_title
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.blog_post_author
{
	margin-top: 5px;
}
.blog_post_author
{
	font-size: 14px;
	font-weight: 300;
	color: #2e3038;
}
.blog_post_text
{
	margin-top: 38px;
}
.blog_post_link
{
	margin-top: 33px;
}
.blog_post_link a
{
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: #ff3500;
}
.blog_post_link a::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background: #ff3500;
	content: '';
}
.page_nav_row
{
	margin-top: 100px;
}
.page_nav ul li
{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: solid 2px #96979b;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.page_nav ul li:not(:last-of-type)
{
	margin-right: 8px;
}
.page_nav ul li a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 28px;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	color: #96979b;
}
.page_nav ul li:hover,
.page_nav ul li.active
{
	background: #ff3500;
	border-color: #ff3500;
}
.page_nav ul li.active a,
.page_nav ul li:hover a
{
	color: #FFFFFF;
}
.page_nav ul li:last-child a i
{
	font-size: 20px;
	line-height: 27px;
	-webkit-transform: translateX(2px);
	-moz-transform: translateX(2px);
	-ms-transform: translateX(2px);
	-o-transform: translateX(2px);
	transform: translateX(2px);
}

.processing > div{
    display: flex;
    align-items: center;
}

.processing > div p{
    margin-left: 10px;
}

.processing img{
    height: auto;
    width: 30px;
}

@media only screen and (max-width: 767px)
{
	.home_title h1
	{
		font-size: 48px;
	}
	.blog_post_image
	{
		width: 100%;
	}
	.blog_post_content
	{
		width: 100%;
		padding-left: 0px;
		padding-top: 25px;
	}
	.blog_post_text
	{
		margin-top: 33px;
	}
}


/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	.home_container
	{
		top: 55%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.home_title
	{
		margin-top: 10px;
	}
	.home_title h1
	{
		font-size: 30px;
	}
	.button_fill,
	.button_border
	{
		height: 38px;
	}
	.button_border a
	{
		font-size: 12px;
		line-height: 36px;
	}
	.button_fill a
	{
		font-size: 12px;
		line-height: 38px;
	}
	.sidebar_list ul li a
	{
		font-size: 14px;
	}
	.blog_post_date
	{
		width: 129px;
		height: 35px;
	}
	.blog_post_date a
	{
		font-size: 12px;
		line-height: 35px;
	}
}


