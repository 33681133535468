@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Poppins:100,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Poppins:100,300,400,500,600,700,800,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

body
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background: #FFFFFF;
	color: #a5a5a5;
}

div
{
	display: block;
	position: relative;
 box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 1.875;
	font-weight: 300;
	color: #929191;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #ffa07f;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a
{
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FFFFFF;
	background: #ffa07f;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: #ff3500;
	color: #FFFFFF;
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
	line-height: 1.2;
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
img
{
	max-width: 100%;
}
button:active
{
	outline: none;
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	transition: all 200ms ease;
}
.trans_300
{
	transition: all 300ms ease;
}
.trans_400
{
	transition: all 400ms ease;
}
.trans_500
{
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.parallax_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
}
.background_image
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.nopadding
{
	padding: 0px !important;
}
.owl-carousel,
.owl-carousel .owl-stage-outer,
.owl-carousel .owl-stage,
.owl-carousel .owl-item
{
	height: 100%;
}
.slide
{
	height: 100%;
}
.button_border
{
	display: inline-block;
	height: 42px;
	border: solid 1px #FFFFFF;
	border-radius: 21px;
	background: transparent;
}
.button_border a
{
	display: block;
	position: relative;
	height: 100%;
	padding-left: 28px;
	padding-right: 31px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 40px;
	border-radius: 21px;
}
.button_border:hover
{
	background: #FFFFFF;
}
.button_border:hover a
{
	color: #ff3500;
}
.button_fill
{
	display: inline-block;
	height: 42px;
	background: #ff3500;
	border-radius: 21px;
	transition: all 200ms ease;
}
.button_fill:hover
{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
}
.button_fill a
{
	display: block;
	width: 100%;
	height: 100%;
	padding-left: 25px;
	padding-right: 25px;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
}


/*********************************
10. General
*********************************/
.section_title h1
{
	font-size: 36px;
	color: #1f2128;
	font-weight: 500;
}
.section_title h1 span
{
	font-weight: 600;
}
.section_title.light h1
{
	color: #FFFFFF;
}
.offset-tp
{
	margin-top: 15px;
}
div.top-loading-bar
{
	z-index:9999999;
	position:fixed;
	top:0;
	left:0;
	height:2px;
	width:100%
}

.style_footer__9UEeb
{
	display: block;
	background: #1f2128;
	padding-top: 48px;
	padding-bottom: 48px;
}

@media only screen and (max-width: 991px)
{
	.style_footer_content_row__2MRDR > div[class^='col']:not(:last-child)
	{
		margin-bottom: 80px;
	}
}
.style_header__FbWg0
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	z-index: 100;
}

.style_header__FbWg0.style_scrolled__1JDJ0
{
	background: rgba(0,0,0,0.63);
}
.style_header_content__1Bt7w
{
	width: 100%;
	height: 86px;
}
.style_header__FbWg0.style_scrolled__1JDJ0 .style_header_content__1Bt7w
{
	height: 70px;
}
.style_logo__23Vv2
{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 33px;
	height: 51px;
	z-index: 1;
}
.style_logo__23Vv2 a
{
	display: block;
	width: 100%;
	height: 100%;
	font-size: 30px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_logo__23Vv2 a span
{
	font-weight: 100;
	margin-right: 5px;
}

.style_signUp__16sZE{
	background-color: #ff3000;
}

.style_signUp__16sZE:hover{
	background-color: #1f2128; 
}
.style_logo__23Vv2 a img
{
	vertical-align: top;
	margin-left: 5px;
}
.style_main_nav__21m2Q ul li:not(:last-of-type)
{
	margin-right: 40px;
}
.style_main_nav__21m2Q ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_main_nav__21m2Q ul li a:hover
{
	color: #ff3000;
}
.style_main_nav__21m2Q ul li a.style_active__UL3Zn
{
	color: #ff3000;
}
.style_header_right__3kzdG
{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 27px;
}
.style_submit__1jhKT
{
	height: 32px;
	border-radius: 16px;
	margin-right: 19px;
	transition: all 200ms ease;
}
.style_submit__1jhKT a
{
	display: block;
	height: 100%;
	line-height: 30px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
}

.style_signUp__16sZE{
	color: #FFFFFF;
	cursor: pointer;
}
.style_submit__1jhKT:hover
{
	border-color: #ff3000;
}
.style_submit__1jhKT:hover a
{
	color: #ff3000;
}
.style_hamburger__R4rJW
{
	display: none;
	margin-left: 20px;
	cursor: pointer;
}
.style_hamburger__R4rJW i
{
	font-size: 28px;
	color: #FFFFFF;
}

.style_menu__2oRh7
{
	position: fixed;
	left: 0;
	top: -100vh;
	width: 100vw;
	height: 100vh;
	padding-right: 70px;
	padding-top: 200px;
	background: #1f2128;
	z-index: 99;
	transition: all 800ms cubic-bezier(.88,.31,.65,.91);
}
.style_menu__2oRh7.style_active__UL3Zn
{
	top: 0;
}
.style_menu_content__1WicZ
{
	width: 100%;
	height: 100%;
}
.style_menu_nav_list__9fQqS li
{
	transform: translateY(-50px);
	visibility: hidden;
	opacity: 0;
	transition: all 800ms ease;
}
.style_menu_nav_list__9fQqS li:first-child
{
	transition-delay: 400ms;
}
.style_menu_nav_list__9fQqS li:nth-child(2)
{
	transition-delay: 500ms;
}
.style_menu_nav_list__9fQqS li:nth-child(3)
{
	transition-delay: 600ms;
}
.style_menu_nav_list__9fQqS li:nth-child(4)
{
	transition-delay: 700ms;
}
.style_menu_nav_list__9fQqS li:nth-child(5)
{
	transition-delay: 800ms;
}
.style_menu_nav_list__9fQqS li:nth-child(6)
{
	transition-delay: 900ms;
}
.style_menu_nav_list__9fQqS li:nth-child(7)
{
	transition-delay: 1000ms;
}
.style_menu_nav_list__9fQqS li:nth-child(8)
{
	transition-delay: 1100ms;
}
.style_menu_nav_list__9fQqS li:nth-child(9)
{
	transition-delay: 1200ms;
}
.style_menu__2oRh7.style_active__UL3Zn .style_menu_nav_list__9fQqS li
{
	transform: translateY(0px);
	visibility: visible;
	opacity: 1;
}
.style_menu_nav_list__9fQqS li a
{
	position: relative;
	font-size: 40px;
	color: #FFFFFF;
	font-weight: 400;
	line-height: 1.3;
	transition: all 400ms ease;
}
.style_menu_nav_list__9fQqS li a:hover
{
	color: #ff3500;
}
.style_menu_nav_list__9fQqS li a.style_active__UL3Zn
{
	color: #ff3500;
}
.style_menu_extra__gyXRS
{
	position: absolute;
	left: 0;
	bottom: 15px;
	width: 100%;
}
.style_menu_submit__2M_s_, .style_menu_submit2__2aG23
{
	display: none;
	margin-bottom: 20px;
}
.style_menu_submit__2M_s_ a
{
	white-space: nowrap;
	line-height: 1.1;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	transition: all 200ms ease;
}
.style_menu_submit__2M_s_ a:hover
{
	color: #ff3500;
}
.style_menu__2oRh7 .style_social__IKeyj
{
	display: none;
}
.style_menu__2oRh7 .style_social__IKeyj ul li:not(:first-child)
{
	margin-left: 8px;
}

@media only screen and (max-width: 1600px)
{
	.style_header_content__1Bt7w
	{
		padding-left: 200px;
	}
}

@media only screen and (max-width: 1199px)
{
	.style_main_nav__21m2Q
	{
		display: none;
	}
	.style_hamburger__R4rJW
	{
		display: block;
	}
}

/*CSS OF THE FORMS IN THE MODAL (SIGNUP, LOGIN)*/

.style_signUpForm__1u5Pd{
	display: flex;
	flex-direction: column;
}

.style_signUpForm__1u5Pd span{
	font-weight: normal;
	color: #999;
	font-size: 12px;
}

.style_signUpForm__1u5Pd input{
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 2px;
	height: 45px;
	padding: 0 15px;
	font-size: 16px;
	outline: none;
}

.style_signUpForm__1u5Pd button{
	background-color: #ff3500;
	color: #ddd;
	font-size: 16px;
	height: 45px;
	outline: none;
	border: none;
	border-radius: 2px;
}

.style_signUpForm__1u5Pd button:hover{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
	transition: 0.5s;
}

#style_errorMessage__14oIi, #style_errorMessage2__ms6ZL, #style_errorMessage3__3WIhl{
	background-color: #e81a1a3c;
	padding: 10px;
	width: 100%;
	display: none;
	color: #ff0000;
}

#style_errorMessage__14oIi.style_sucess__3SpDF{
	background-color: #43e81a3c;
	padding: 10px;
	width: 100%;
	display: none;
	color: #00ff2a;
}

.style_d__2Y9Y2{
	background-color: transparent;
	border: none;
}

.style_emailConfirmation__yfphQ, .style_image__3i1BN{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.style_emailConfirmation__yfphQ img{
	width: 100%;
	height: auto;
	width: 50%;
}


.style_LogOutButton__1opXT{
	background-color: #ff0000;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.style_LogOutButton__1opXT:hover{
	background-color: #2e3038;
	transition: 0.2s;
}

.style_CancelLogOutButton__3AnIu{
	background-color: #bababa;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.style_CancelLogOutButton__3AnIu:hover{
	background-color: #a8a8a8;
}

.style_Loading__3Bz8x{
	text-align: center;
}

.style_Loading__3Bz8x img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}

@media only screen and (max-width: 991px)
{
	.style_submit__1jhKT, .style_userS__2GzKT
	{
		display: none;
	}
	.style_menu_submit__2M_s_, .style_menu_submit2__2aG23
	{
		display: block;
	}
}


.style_forgot__11L9p p{
	color: #ff0000;
	margin-top: 10px;
	cursor: pointer;
}

.style_forgot__11L9p p:hover{
	text-decoration: underline;
}

.style_Loading__3Bz8x{
	text-align: center;
}

.style_Loading__3Bz8x img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}

.style_active__UL3Zn{
	margin-right: 10px;
}

.style_noactive__Tg5HO{
	display: none;
}
.style_home__3eai7
{
	height: 830px;
}

.style_home_container__8Gxtt
{
	position: absolute;
	top: 30%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.style_highlight_word__1moDT {
	text-decoration: underline;
	transition: background-color 0.5s linear;
  }

  .style_highlight_word__1moDT:hover {
	background-color: #ff3000; /* Change this to your desired highlight color */
  }

.style_background_image__lyqci
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.style_tags__2bmAk ul li
{
	display: inline-block;
	height: 20px;
	background: #ff3500;
	border-radius: 3px;
	margin-bottom: 5px;
	transition: all 200ms ease;
}
.style_tags__2bmAk ul li:hover
{
	background: #2e3038;
}
.style_tags__2bmAk ul li:not(:last-of-type)
{
	margin-right: 10px;
}
.style_tags__2bmAk ul li a
{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	font-size: 12.73px;
	line-height: 20px;
	color: #FFFFFF;
	font-weight: 500;
	padding-left: 6px;
	padding-right: 7px;
}
.style_home_title__15nIG
{
	margin-top: 12px;
}
.style_home_title__15nIG h1
{
	font-size: 36px;
	color: #FFFFFF;
	font-weight: 500;
}
.style_home_subtitle__Z0_1R
{
	font-size: 18px;
	font-weight: 300;
	color: #FFFFFF;
	line-height: 1.5;
	margin-top: 14px;
}
.style_track__ynjQo
{
	max-height: 166px;
}
.style_track_home__1BqRM
{
	margin-top: 28px;
}
.style_home_button__3MnV2
{
	margin-top: 30px;
}
.style_track_info__1iUvk
{
	margin-top: 19px;
}
.style_track_info__1iUvk ul li a,
.style_track_info__1iUvk ul li
{
	font-size: 12px;
	color: #FFFFFF;
	font-weight: 300;
	line-height: 1.1;
}
.style_track_info__1iUvk ul li a:hover
{
	color: #ff3500;
}
.style_track_info__1iUvk ul li:not(:last-of-type)::after
{
	display: inline-block;
	content: '|';
	margin-left: 12px;
	margin-right: 12px;
}

/*********************************
6. Shows
*********************************/

.style_shows__v-TZ8
{
	/* background: #FFFFFF; */
	background: #2e3038;
	padding-top: 93px;
	padding-bottom: 99px;
}
.style_shows_title__3_F4s
{
	font-size: 24px;
	color: #FFFFFF;
	/* color: #1f2128; */
	font-weight: 500;
}
.style_shows_row__33cUs
{
	margin-top: 38px;
}
.style_shows_row__33cUs > div[class^='col']
{
	margin-bottom: 50px;
}
.style_shows_2_button__1tY9q
{
	margin-top: 20px;
}
.style_shows_2_button__1tY9q:hover
{
	background: #FFFFFF;
}
.style_shows_2_button__1tY9q:hover a
{
	color: #ff3500;
}
.style_show__scqdO
{
	border-radius: 3px;
	overflow: hidden;
	background: #f6f6f6;
}
.style_show_image__7eFkV a
{
	display: block;
	width: 100%;
	height: 100%;
}
.style_show_image__7eFkV img
{
	vertical-align: middle;
	max-width: 100%;
}
.style_show_image__7eFkV img:hover
{
 transition: all 200ms ease;
	filter: brightness(50%);
}
.style_show_content__6Ooak
{
	padding-top: 20px;
	padding-left: 31px;
	padding-bottom: 22px;
	padding-right: 30px;
}
.style_show_tags__1m7UF
{
	position: absolute;
	top: 24px;
	left: 24px;
}
.style_show_date__1Kr0t a
{
	font-size: 14px;
	font-weight: 300;
	color: #2e3038;
}
.style_show_title__2RWIX
{
	margin-top: 11px;
}
.style_show_title__2RWIX a
{
	font-size: 18px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.5;
}
.style_show_title__2RWIX a:hover,
.style_show_date__1Kr0t a:hover
{
	color: #ff3500;
}
.style_show_info__34moE
{
	margin-top: 23px;
}
.style_show_fav__3yOeu
{
	margin-left: -3px;
}
.style_show_fav_icon__FkRS7
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.style_show_comments__37TJQ
{
	margin-left: 17px;
}
.style_show_comments__37TJQ a
{
	display: block;
}
.style_show_comments_icon__12MKL
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.style_show_info_icon__ZDKzS svg
{
	max-width: 100%;
	max-height: 100%;
}
.style_svg__2HYFn path, .style_svg__2HYFn rect, .style_svg__2HYFn polygon
{
    fill: #7b7b7b;
    transition: all 200ms ease;
}
.style_show_fav_icon__FkRS7.style_active__2bye4 .style_svg__2HYFn path,
.style_show_fav_icon__FkRS7:hover .style_svg__2HYFn path,
.style_show_comments__37TJQ:hover .style_svg__2HYFn path
{
	fill: #ff3500;
}
.style_show_fav_count__3ICim,
.style_show_comments_count__69D1j
{
	font-size: 14px;
	font-weight: 500;
	color: #2e3038;
	margin-left: 7px;
	margin-bottom: 2px;
}
.style_show_comments__37TJQ:hover .style_show_comments_count__69D1j
{
	color: #ff3500;
}
.style_show_play_icon__vNOQG
{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.85);
	width: 50px;
	height: 50px;
	pointer-events: none;
	transition: all 400ms ease;
}
.style_show_image__7eFkV:hover .style_show_play_icon__vNOQG
{
	transform: translate(-50%, -50%) scale(1);
}



/*********************************
8. Milestones
*********************************/

.style_milestones__24WlM
{
	padding-top: 65px;
	padding-bottom: 112px;
}
.style_milestones_row__1CQil
{
	margin-top: 69px;
}
.style_milestones_row__1CQil > div[class^='col']
{
	margin-bottom: 60px;
}
.style_milestone_content__1pR3U
{
	height: 80px;
}
.style_milestone_icon__pJN6O
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100%;
	max-width: none;
	opacity: 0.25;
	pointer-events: none;
}
.style_milestone_icon__pJN6O i
{
	color: #FFFFFF;
	font-size: 5.5em;
}
.style_milestone_counter__336VA
{
	font-size: 24px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 80px;
}
.style_milestone_title__1rxG_
{
	font-size: 18px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 0.75;
	margin-top: 33px;
}
/*********************************
9. Newsletter
*********************************/
.style_newsletter__3weAh
{
	padding-top: 74px;
	padding-bottom: 166px;
}
.style_newsletter_text__yyNPN
{
	max-width: 510px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 18px;
}
.style_newsletter_text__yyNPN p
{
	font-size: 16px;
	font-weight: 300;
	color: #FFFFFF;
	line-height: 1.5;
}
.style_newsletter_row__1QATL
{
	margin-top: 63px;
}
.style_newsletter_form_container__9qI0p
{
	width: 100%;
}
.style_newsletter_form__3udGw
{
	display: block;
}
.style_newsletter_input__3FemB
{
	display: block;
	width: calc(100% - 260px);
	height: 54px;
	background: #FFFFFF;
	border: none;
	outline: none;
	border-radius: 27px;
	font-size: 18px;
	color: #1f2128;
	padding-left: 30px;
}
.style_newsletter_button__IQ9wu
{
	width: 230px;
	height: 54px;
	border: none;
	outline: none;
	border-radius: 27px;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	cursor: pointer;
}

.style_intro__34KRz
{
	background: #FFFFFF;
	padding-top: 97px;
	padding-bottom: 92px;
}
.style_intro_text__3aaFN
{
	margin-top: 13px;
}
.style_intro_button__3UHsT
{
	width: 161px;
	margin-top: 29px;
}

.style_button_fill__2CjNk
{
	display: inline-block;
	height: 42px;
	background: #ff3500;
	border-radius: 21px;
	transition: all 200ms ease;
}

.style_button_fill__2CjNk:hover
{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
}

.style_button_fill__2CjNk a
{
	display: block;
	width: 100%;
	height: 100%;
	padding-left: 25px;
	padding-right: 25px;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
}

.style_section_title__3PywR h1
{
	font-size: 36px;
	color: #1f2128;
	font-weight: 500;
}
.style_section_title__3PywR h1 span
{
	font-weight: 600;
}
.style_section_title__3PywR.style_light__30TDh h1
{
	color: #FFFFFF;
}

@media screen and (max-width: 954px) {
	.style_home_title__15nIG h1{
		font-size: 3vw;
		text-align: center;
	}
	.style_home_container__8Gxtt{
		margin: 0px;
	}
}
#style_SearchBar__x0iDv .style_form__1Zeki{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 80vw;
    height: 72px;
    border-radius: 30px;
    padding: 20px;
}

#style_SearchBar__x0iDv .style_form__1Zeki input, button{
    outline: none;
    border: none;
    background-color: transparent;
}

#style_SearchBar__x0iDv .style_form__1Zeki input{
    width: 100%;
}

#style_SearchBar__x0iDv.style_active__P-0Fq .style_form__1Zeki{
    border-radius: 0px;
}

#style_SearchBar__x0iDv .style_filter__2I4x8 select{
    width: 80px;
}

#style_searchresults__3iC7U{
    background-color: #fff;
    width: 80vw;
    max-height: 35vh;
    overflow-y: auto;
    overflow-x:hidden;
}

#style_searchresults__3iC7U > div{
    border-bottom: 1px solid rgb(216, 216, 216);
    padding: 10px;
    cursor: pointer;
    display: flex;
}

#style_searchresults__3iC7U > div .style_imageResult__1QO1H{
    margin-right: 10px;
}

#style_searchresults__3iC7U > div:hover{
    background-color: rgb(216, 216, 216);
    transition: 0.5s;
}


#style_searchresults__3iC7U.style_noactive__3zRBp{
    visibility: hidden;
}

.style_isAlreadyClassified__1Y3Eo a, .style_classifyButton__y-ksC button{
    color: #fff;
    background-color: #ff3000;
    padding: 5px;
    text-decoration: underline;
    border-radius: 10px;
    margin-top: 10px;
}

.style_classifyButton__y-ksC button{
    text-decoration: none;
}
.style_isAlreadyClassified__1Y3Eo a:hover, .style_classifyButton__y-ksC button:hover{
    background-color: #ff6341;
}

.style_isAlreadyClassified__1Y3Eo p{
    color: black;
    font-weight: bold;
}


.style_home__2c9rT
{
	height: 346px;
}
.style_home_container__2Sz9h
{
	width: 100%;
	height: 100%;
}
.style_home_title__3anES
{
	
}
.style_home_title__3anES h1
{
	font-size: 60px;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}

/*********************************
6. Blog
*********************************/

.style_blog__3osns
{
	background: #FFFFFF;
	padding-top: 109px;
	padding-bottom: 106px;
}
.style_blog_post__3Oc_U:not(:last-child)
{
	margin-bottom: 77px;
}
.style_blog_post_image__2pZke
{
	width: 42.5%;
	flex: 0 0 auto;
}
.style_blog_post_image__2pZke iframe
{
	vertical-align: middle;
	max-width: 100%;
}
.style_blog_post_content__KApcG
{
	width: 57.5%;
	flex: 0 0 auto;
	padding-left: 30px;
}
.style_blog_post_date__2eTOp
{
	position: absolute;
	left: -6px;
	top: -9px;
	width: 161px;
	height: 42px;
	border-radius: 21px;
	background: #ff3500;
}
.style_blog_post_date__2eTOp a
{
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_blog_post_title__1lhH1
{
	margin-top: -4px;
}
.style_blog_post_title__1lhH1
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.style_blog_post_author__2lsg6
{
	margin-top: 5px;
}
.style_blog_post_author__2lsg6
{
	font-size: 14px;
	font-weight: 300;
	color: #2e3038;
}
.style_blog_post_text__r641e
{
	margin-top: 38px;
}
.style_blog_post_link__z-09C
{
	margin-top: 33px;
}
.style_blog_post_link__z-09C a
{
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: #ff3500;
}
.style_blog_post_link__z-09C a::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background: #ff3500;
	content: '';
}
.style_page_nav_row__zUYa8
{
	margin-top: 100px;
}
.style_page_nav__3vURU ul li
{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: solid 2px #96979b;
	transition: all 200ms ease;
}
.style_page_nav__3vURU ul li:not(:last-of-type)
{
	margin-right: 8px;
}
.style_page_nav__3vURU ul li a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 28px;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	color: #96979b;
}
.style_page_nav__3vURU ul li:hover,
.style_page_nav__3vURU ul li.style_active__5YRgs
{
	background: #ff3500;
	border-color: #ff3500;
}
.style_page_nav__3vURU ul li.style_active__5YRgs a,
.style_page_nav__3vURU ul li:hover a
{
	color: #FFFFFF;
}
.style_page_nav__3vURU ul li:last-child a i
{
	font-size: 20px;
	line-height: 27px;
	transform: translateX(2px);
}

@media only screen and (max-width: 767px)
{
	.style_home_title__3anES h1
	{
		font-size: 48px;
	}
	.style_blog_post_image__2pZke
	{
		width: 100%;
	}
	.style_blog_post_content__KApcG
	{
		width: 100%;
		padding-left: 0px;
		padding-top: 25px;
	}
	.style_blog_post_text__r641e
	{
		margin-top: 33px;
	}
}

/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	.style_home_container__2Sz9h
	{
		top: 55%;
		transform: translateY(-50%);
	}
	.style_home_title__3anES
	{
		margin-top: 10px;
	}
	.style_home_title__3anES h1
	{
		font-size: 30px;
	}
	.style_button_fill__3O_NS,
	.style_button_border__3ROwu
	{
		height: 38px;
	}
	.style_button_border__3ROwu a
	{
		font-size: 12px;
		line-height: 36px;
	}
	.style_button_fill__3O_NS a
	{
		font-size: 12px;
		line-height: 38px;
	}
	.style_sidebar_list__D5UFr ul li a
	{
		font-size: 14px;
	}
	.style_blog_post_date__2eTOp
	{
		width: 129px;
		height: 35px;
	}
	.style_blog_post_date__2eTOp a
	{
		font-size: 12px;
		line-height: 35px;
	}
}


.style_Loading__1LjPD{
	text-align: center;
}

.style_Loading__1LjPD img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}


.style_page_nav_row__16_Ei
{
	margin-top: 100px;
}
.style_page_nav__1TXw4 ul li
{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: solid 2px #96979b;
	transition: all 200ms ease;
}
.style_page_nav__1TXw4 ul li:not(:last-of-type)
{
	margin-right: 8px;
}
.style_page_nav__1TXw4 ul li a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 28px;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	color: #96979b;
}
.style_page_nav__1TXw4 ul li:hover,
.style_page_nav__1TXw4 ul li.style_active__2SeZj
{
	background: #ff3500;
	border-color: #ff3500;
}
.style_page_nav__1TXw4 ul li.style_active__2SeZj a,
.style_page_nav__1TXw4 ul li:hover a
{
	color: #FFFFFF;
}
.style_page_nav__1TXw4 ul li:last-child a i
{
	font-size: 20px;
	line-height: 27px;
	transform: translateX(2px);
}
.style_home__3xvGa
{
	height: 346px;
}
.style_home_container__sObzp
{
	width: 100%;
	height: 100%;
}
.style_home_title__3cKR8
{
	
}
.style_home_title__3cKR8 h1
{
	font-size: 60px;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
}

/*********************************
6. Blog
*********************************/

.style_blog__ZK1jl
{
	background: #FFFFFF;
	padding-top: 109px;
	padding-bottom: 106px;
}
.style_blog_post__1Oyg2:not(:last-child)
{
	margin-bottom: 77px;
}
.style_blog_post_image__1XM3u
{
	width: 42.5%;
	flex: 0 0 auto;
}
.style_blog_post_image__1XM3u iframe
{
	vertical-align: middle;
	max-width: 100%;
}
.style_blog_post_content__3aQHX
{
	width: 57.5%;
	flex: 0 0 auto;
	padding-left: 30px;
}
.style_blog_post_date__1JxS-
{
	position: absolute;
	left: -6px;
	top: -9px;
	width: 161px;
	height: 42px;
	border-radius: 21px;
	background: #ff3500;
}
.style_blog_post_date__1JxS- a
{
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_blog_post_title__1dtuY
{
	margin-top: -4px;
}

.style_blog_post_title__1dtuY a{
	color: #111;
}

.style_blog_post_title__1dtuY a:hover{
	color: #ff3500;
}


.style_blog_post_title__1dtuY
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.style_blog_post_author__17Q1X
{
	margin-top: 5px;
}
.style_blog_post_author__17Q1X
{
	font-size: 14px;
	font-weight: 300;
	color: #2e3038;
}
.style_blog_post_text__22BqZ
{
	margin-top: 38px;
}
.style_blog_post_link__1rThU
{
	margin-top: 33px;
}
.style_blog_post_link__1rThU a
{
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: #ff3500;
}
.style_blog_post_link__1rThU a::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background: #ff3500;
	content: '';
}
.style_page_nav_row__2bJPV
{
	margin-top: 100px;
}
.style_page_nav__q5vOY ul li
{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: solid 2px #96979b;
	transition: all 200ms ease;
}
.style_page_nav__q5vOY ul li:not(:last-of-type)
{
	margin-right: 8px;
}
.style_page_nav__q5vOY ul li a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 28px;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	color: #96979b;
}
.style_page_nav__q5vOY ul li:hover,
.style_page_nav__q5vOY ul li.style_active__mRjPC
{
	background: #ff3500;
	border-color: #ff3500;
}
.style_page_nav__q5vOY ul li.style_active__mRjPC a,
.style_page_nav__q5vOY ul li:hover a
{
	color: #FFFFFF;
}
.style_page_nav__q5vOY ul li:last-child a i
{
	font-size: 20px;
	line-height: 27px;
	transform: translateX(2px);
}

.style_processing__3ve7J > div{
    display: flex;
    align-items: center;
}

.style_processing__3ve7J > div p{
    margin-left: 10px;
}

.style_processing__3ve7J img{
    height: auto;
    width: 30px;
}

@media only screen and (max-width: 767px)
{
	.style_home_title__3cKR8 h1
	{
		font-size: 48px;
	}
	.style_blog_post_image__1XM3u
	{
		width: 100%;
	}
	.style_blog_post_content__3aQHX
	{
		width: 100%;
		padding-left: 0px;
		padding-top: 25px;
	}
	.style_blog_post_text__22BqZ
	{
		margin-top: 33px;
	}
}


/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	.style_home_container__sObzp
	{
		top: 55%;
		transform: translateY(-50%);
	}
	.style_home_title__3cKR8
	{
		margin-top: 10px;
	}
	.style_home_title__3cKR8 h1
	{
		font-size: 30px;
	}
	.style_button_fill__O5mhC,
	.style_button_border__1KgZX
	{
		height: 38px;
	}
	.style_button_border__1KgZX a
	{
		font-size: 12px;
		line-height: 36px;
	}
	.style_button_fill__O5mhC a
	{
		font-size: 12px;
		line-height: 38px;
	}
	.style_sidebar_list__2vTsP ul li a
	{
		font-size: 14px;
	}
	.style_blog_post_date__1JxS-
	{
		width: 129px;
		height: 35px;
	}
	.style_blog_post_date__1JxS- a
	{
		font-size: 12px;
		line-height: 35px;
	}
}




.style_tags__1POo7
{
	position: absolute;
	top: 13px;
	left: 11px;
}
.style_tags__1POo7 ul li
{
    display: inline-block;
    height: 20px;
    background: #ff3500;
    border-radius: 3px;
    margin-bottom: 5px;
    transition: all 200ms ease;
}
.style_tags__1POo7 ul li:not(:last-of-type)
{
    margin-right: 10px;
}
.style_tags__1POo7 ul li:hover
{
	background: #2e3038;
}
.style_tags__1POo7 ul li a
{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 12.73px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: 500;
    padding-left: 6px;
    padding-right: 7px;
}


/*********************************
3. Header
*********************************/

.style_header__2xJS7
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	z-index: 100;
}
.style_header__2xJS7.style_scrolled__ctCFR
{
	background: rgba(0,0,0,0.63);
}
.style_header_content__2Esjd
{
	width: 100%;
	height: 86px;
}
.style_header__2xJS7.style_scrolled__ctCFR .style_header_content__2Esjd
{
	height: 70px;
}
.style_logo__3S_my
{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 33px;
	height: 51px;
	z-index: 1;
}
.style_logo__3S_my a
{
	display: block;
	width: 100%;
	height: 100%;
	font-size: 30px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_logo__3S_my a span
{
	font-weight: 100;
	margin-right: 5px;
}
.style_logo__3S_my a img
{
	vertical-align: top;
	margin-left: 5px;
}
.style_main_nav__35DqI ul li:not(:last-of-type)
{
	margin-right: 40px;
}
.style_main_nav__35DqI ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_main_nav__35DqI ul li a:hover
{
	color: #ff3000;
}
.style_main_nav__35DqI ul li a.style_active__OBDuk
{
	color: #ff3000;
}
.style_header_right__2Iclo
{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 27px;
}
.style_submit__3flh0
{
	height: 32px;
	border-radius: 16px;
	margin-right: 19px;
	transition: all 200ms ease;
}
.style_submit__3flh0 a
{
	display: block;
	height: 100%;
	line-height: 30px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	padding-left: 20px;
	padding-right: 20px;
}
.style_submit__3flh0:hover
{
	border-color: #ff3000;
}
.style_submit__3flh0:hover a
{
	color: #ff3000;
}
.style_hamburger__3Lgrd
{
	display: none;
	margin-left: 20px;
	cursor: pointer;
}
.style_hamburger__3Lgrd i
{
	font-size: 28px;
	color: #FFFFFF;
}

/*********************************
4. Menu
*********************************/

.style_menu__3_xhW
{
	position: fixed;
	left: 0;
	top: -100vh;
	width: 100vw;
	height: 100vh;
	padding-right: 70px;
	padding-top: 200px;
	background: #1f2128;
	z-index: 99;
	transition: all 800ms cubic-bezier(.88,.31,.65,.91);
}
.style_menu__3_xhW.style_active__OBDuk
{
	top: 0;
}
.style_menu_content__25GRN
{
	width: 100%;
	height: 100%;
}
.style_menu_nav_list__1_omE li
{
	transform: translateY(-50px);
	visibility: hidden;
	opacity: 0;
	transition: all 800ms ease;
}
.style_menu_nav_list__1_omE li:first-child
{
	transition-delay: 400ms;
}
.style_menu_nav_list__1_omE li:nth-child(2)
{
	transition-delay: 500ms;
}
.style_menu_nav_list__1_omE li:nth-child(3)
{
	transition-delay: 600ms;
}
.style_menu_nav_list__1_omE li:nth-child(4)
{
	transition-delay: 700ms;
}
.style_menu_nav_list__1_omE li:nth-child(5)
{
	transition-delay: 800ms;
}
.style_menu_nav_list__1_omE li:nth-child(6)
{
	transition-delay: 900ms;
}
.style_menu_nav_list__1_omE li:nth-child(7)
{
	transition-delay: 1000ms;
}
.style_menu_nav_list__1_omE li:nth-child(8)
{
	transition-delay: 1100ms;
}
.style_menu_nav_list__1_omE li:nth-child(9)
{
	transition-delay: 1200ms;
}
.style_menu__3_xhW.style_active__OBDuk .style_menu_nav_list__1_omE li
{
	transform: translateY(0px);
	visibility: visible;
	opacity: 1;
}
.style_menu_nav_list__1_omE li a
{
	position: relative;
	font-size: 40px;
	color: #FFFFFF;
	font-weight: 400;
	line-height: 1.3;
	transition: all 400ms ease;
}
.style_menu_nav_list__1_omE li a:hover
{
	color: #ff3500;
}
.style_menu_nav_list__1_omE li a.style_active__OBDuk
{
	color: #ff3500;
}
.style_menu_extra__1VTgw
{
	position: absolute;
	left: 0;
	bottom: 15px;
	width: 100%;
}
.style_menu_submit__1ihYR
{
	display: none;
	margin-bottom: 20px;
}
.style_menu_submit__1ihYR a
{
	white-space: nowrap;
	line-height: 1.1;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	transition: all 200ms ease;
}
.style_menu_submit__1ihYR a:hover
{
	color: #ff3500;
}
.style_menu__3_xhW .style_social__3MRsd
{
	display: none;
}
.style_menu__3_xhW .style_social__3MRsd ul li:not(:first-child)
{
	margin-left: 8px;
}

/*********************************
5. Home
*********************************/

.style_home__13HOL
{
	height: 346px;
}
.style_home_container__3cP-n
{
	width: 100%;
	height: 100%;
}
.style_home_title__2PyZ1
{
	
}
.style_home_title__2PyZ1 h1
{
	font-size: 60px;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}

/*********************************
6. Sidebar
*********************************/

.style_sidebar__1KQmZ
{
	width: 100%;
	margin-top: -3px;
	z-index: 1;
}
.style_sidebar_title__ji7if
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.style_search_form__2iKXC
{
	display: block;
	position: relative;
	width: 100%;
	margin-top: 22px;
}
.style_search_input__2_hyU
{
	width: 100%;
	height: 32px;
	padding-left: 18px;
	background: #f7f7f7;
	border: none;
	outline: none;
	border-radius: 16px;
}
.style_search_input__2_hyU::-webkit-input-placeholder
{
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
}
.style_search_input__2_hyU:-moz-placeholder
{
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
}
.style_search_input__2_hyU::-moz-placeholder
{
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
} 
.style_search_input__2_hyU:-ms-input-placeholder
{ 
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
}
.style_search_input__2_hyU::input-placeholder
{
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
}
.style_search_button__1oaSn
{
	position: absolute;
	top: 0;
	right: 5px;
	width: 32px;
	height: 32px;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}
.style_sidebar_list__3VmZ6
{
	margin-top: 36px;
}
.style_sidebar_list__3VmZ6 ul
{
	margin-top: 19px;
}
.style_sidebar_list__3VmZ6 ul li:not(:last-of-type)
{
	margin-bottom: 3px;
}
.style_sidebar_list__3VmZ6 ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #5e5f62;
}
.style_sidebar_list__3VmZ6 ul li a:hover
{
	color: #ff3500;
}
.style_sidebar_tags__2zwz_
{
	margin-top: 38px;
}
.style_sidebar__1KQmZ .style_tags__1POo7
{
	position: relative;
	top: auto;
	left: auto;
	margin-top: 24px;
}
.style_sidebar_archive__3guC2
{
	margin-top: 30px;
}
.style_dropdown__1SFIr
{
	width: 100%;
	background: #f7f7f7;
	border-radius: 3px;
	margin-top: 24px;
}
.style_dropdown_selected__3UQdc
{
	position: relative;
	width: 100%;
	height: 32px;
	cursor: pointer;
	padding-left: 10px;
	padding-right: 15px;
	font-size: 14px;
	font-weight: 400;
	color: #2e3038;
}
.style_dropdown_selected__3UQdc i
{
	font-size: 12px;
	color: #2e3038;
}
.style_dropdown__1SFIr > ul > li > ul
{
	position: absolute;
	right: 0;
	top: 100%;
	width: 100%;
	padding-top: 15px;
	background: #FFFFFF;
	visibility: hidden;
	opacity: 0;
	transition: all 200ms ease;
}
.style_dropdown__1SFIr > ul > li > ul li
{
	padding-left: 10px;
	padding-right: 15px;
	padding-bottom: 7px;
}
.style_dropdown__1SFIr > ul > li > ul li:not(:last-of-type)
{
	margin-bottom: 8px;
	border-bottom: solid 1px rgba(0,0,0,0.05);
}
.style_dropdown__1SFIr > ul > li > ul li a
{
	display: block;
	font-size: 14px;
	font-weight: 400;
	color: #2e3038;
}
.style_dropdown__1SFIr > ul > li > ul li a:hover
{
	color: #ff3500;
}
.style_dropdown_selected__3UQdc:hover > ul
{
	visibility: visible;
	opacity: 1;
}

/*********************************
7. Blog
*********************************/

.style_blog__3Im-s
{
	background: #FFFFFF;
	padding-top: 109px;
	padding-bottom: 106px;
}
.style_blog_post__4Hkq9:not(:last-child)
{
	margin-bottom: 77px;
}
.style_blog_post_image__LVpK6
{
	width: 42.5%;
	flex: 0 0 auto;
}
/* .blog_post_image img:hover
{
	-webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
	filter: brightness(50%);
} */
.style_blog_post_content__p1Pfn
{
	width: 57.5%;
	flex: 0 0 auto;
	padding-left: 30px;
}
.style_blog_post_date__35a1f
{
	position: absolute;
	left: -6px;
	top: -9px;
	width: 161px;
	height: 42px;
	border-radius: 21px;
	background: #ff3500;
}
.style_blog_post_date__35a1f a
{
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_blog_post_title__BvEnA
{
	margin-top: -4px;
}
.style_blog_post_title__BvEnA a
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.style_blog_post_author__3nxhZ
{
	margin-top: 5px;
}
.style_blog_post_author__3nxhZ,
.style_blog_post_author__3nxhZ a
{
	font-size: 14px;
	font-weight: 300;
	color: #2e3038;
}
.style_blog_post_title__BvEnA a:hover,
.style_blog_post_author__3nxhZ a:hover
{
	color: #ff3500;
}
.style_blog_post_text__kaRGJ
{
	margin-top: 38px;
}
.style_blog_post_link__1OY_C
{
	margin-top: 33px;
}
.style_blog_post_link__1OY_C a
{
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: #ff3500;
}
.style_blog_post_link__1OY_C a::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background: #ff3500;
	content: '';
}
.style_page_nav_row__IZUuy
{
	margin-top: 100px;
}
.style_page_nav__3qyQ- ul li
{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: solid 2px #96979b;
	transition: all 200ms ease;
}
.style_page_nav__3qyQ- ul li:not(:last-of-type)
{
	margin-right: 8px;
}
.style_page_nav__3qyQ- ul li a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 28px;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	color: #96979b;
}
.style_page_nav__3qyQ- ul li:hover,
.style_page_nav__3qyQ- ul li.style_active__OBDuk
{
	background: #ff3500;
	border-color: #ff3500;
}
.style_page_nav__3qyQ- ul li.style_active__OBDuk a,
.style_page_nav__3qyQ- ul li:hover a
{
	color: #FFFFFF;
}
.style_page_nav__3qyQ- ul li:last-child a i
{
	font-size: 20px;
	line-height: 27px;
	transform: translateX(2px);
}

.style_notFound__2zyu8{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.style_notFound__2zyu8 img{
	height: 30em;
}

.style_notFound__2zyu8 h1{
	color: #ff3000;
	font-size: 30px;
}


@media only screen and (max-width: 991px)
{
	.style_sidebar_col__3WTVJ
	{
		margin-top: 100px;
	}
}


/************
10. 767px
************/

@media only screen and (max-width: 767px)
{
	.style_blog_post_image__LVpK6
	{
		width: 100%;
	}
	.style_blog_post_content__p1Pfn
	{
		width: 100%;
		padding-left: 0px;
		padding-top: 25px;
	}
	.style_blog_post_text__kaRGJ
	{
		margin-top: 33px;
	}
}

#style_SearchResults__2L3sh.style_active__OBDuk{
	margin-bottom: 30px;
	padding: 10px;
	border-bottom: 1px solid #96979b;
}

#style_SearchResults__2L3sh.style_noactive__234Kg{
	display: none;
}
/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	.style_sidebar_list__3VmZ6 ul li a
	{
		font-size: 14px;
	}
	.style_blog_post_date__35a1f
	{
		width: 129px;
		height: 35px;
	}
	.style_blog_post_date__35a1f a
	{
		font-size: 12px;
		line-height: 35px;
	}
}


.style_delete_button__2Iw3x{
	width: 100px;
	height: 40px;
	margin-top: 5px;
	background-color: #ff4f4f;
	border-radius: 10px;
	color: #FFFFFF;
}

.style_delete_button__2Iw3x:hover{
	transition: 0.5s;
	background-color: #c30000;
}



.style_LogOutButton__3HUXH{
	background-color: #ff0000;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.style_LogOutButton__3HUXH:hover{
	background-color: #2e3038;
	transition: 0.2s;
}

.style_CancelLogOutButton__3MD-F{
	background-color: #bababa;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.style_CancelLogOutButton__3MD-F:hover{
	background-color: #a8a8a8;
}


.style_Loading__L4BD2{
	text-align: center;
}

.style_Loading__L4BD2 img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}

.style_home__3CNXe {
	height: 346px;
}

.style_home_container__3TAS1 {
	width: 100%;
	height: 100%;
}

.style_home_title__36jwJ {}

.style_home_title__36jwJ h1 {
	font-size: 60px;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}


.style_custom-popover__Q6_f3 {
	--bs-popover-max-width: 200px;
	--bs-popover-border-color: var(--bs-primary);
	--bs-popover-header-bg: var(--bs-primary);
	--bs-popover-header-color: var(--bs-white);
	--bs-popover-body-padding-x: 1rem;
	--bs-popover-body-padding-y: .5rem;
}


.style_about__1Dwvs {
	background: #FFFFFF;
	padding-top: 103px;
}

.style_about_content__-w0Qo {
	width: calc(100% - 390px);
	margin-top: 35px;
}

.style_about_image__2rZGo {
	width: 390px;
	padding-right: 30px;
}

.style_about_text__16xDY {
	margin-top: 36px;
}

.style_about__1Dwvs .style_social__24VKR {
	margin-top: 40px;
}

.style_about__1Dwvs .style_social__24VKR ul li {
	width: 48px;
	height: 48px;
	border-color: #bba9ab;
}

.style_social__24VKR ul li:not(:first-child) {
	margin-left: 0;
}

.style_about__1Dwvs .style_social__24VKR ul li:not(:last-of-type) {
	margin-right: 6px;
}

.style_about__1Dwvs .style_social__24VKR ul li a i {
	font-size: 22px;
	color: #bba9ab;
	line-height: 46px;
}

.style_about__1Dwvs .style_social__24VKR ul li:hover {
	border-color: #ff3500;
}

.style_about__1Dwvs .style_social__24VKR ul li:hover a i {
	color: #ff3500;
}


.style_guests__1MSNJ {
	margin-top: 53px;
}

.style_guests_container__1RGxk {
	margin-top: 40px;
}

.style_guest_container__TMejA {
	width: calc(100% / 3);
}

.style_guest__T4BOW {
	max-width: 166px;
	border-radius: 3px;
	overflow: hidden;
	margin-bottom: 40px;
}

.style_guest_content__2wn-0 {
	padding-top: 29px;
}

.style_guest_name__6kbtY a {
	font-size: 18px;
	font-weight: 500;
	color: #ff3500;
	line-height: 1.2;
}

.style_guest_name__6kbtY a:hover {
	color: rgba(0, 0, 0, 1);
}

.style_guest_title__nx5bY {
	font-size: 14px;
	font-weight: 500;
	color: #b1b2b4;
	margin-top: 2px;
}

.style_section_title__3ka9o {
	font-size: 24px;
	color: #2e3038;
	font-weight: 500;
}

.style_section_title__3ka9o h1 {
	font-size: 36px;
	color: #1f2128;
	font-weight: 500;
}

.style_section_title__3ka9o h1 span {
	font-weight: 600;
}

.style_section_title__3ka9o.style_light__1sJKK h1 {
	color: #FFFFFF;
}

.style_milestones__cWykF {
	padding-top: 65px;
	padding-bottom: 112px;
}

.style_milestones_row__1CD9Y {
	margin-top: 69px;
}

.style_milestones_row__1CD9Y>div[class^='col'] {
	margin-bottom: 60px;
}

.style_milestone__3XBpC {}

.style_milestone_content__YgfqS {
	height: 80px;
}

.style_milestone_icon__3ldDQ {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100%;
	max-width: none;
	opacity: 0.3;
	pointer-events: none;
}

.style_milestone_icon__3ldDQ i {
	color: #FFFFFF;
	font-size: 5.5em;
}

.style_milestone_counter__2PCko {
	font-size: 24px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 80px;
}

.style_milestone_title__atCAH {
	font-size: 18px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 0.75;
	margin-top: 33px;
}

@media only screen and (max-width: 991px) {
	.style_submit__1oujR {
		display: none;
	}

	.style_menu_submit__6j9uW {
		display: block;
	}

	.style_about_content__-w0Qo {
		width: 100%;
		margin-top: 0;
	}

	.style_about_image__2rZGo {
		margin-top: 60px;
	}
}
@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
4. Menu
5. Home
6. Sidebar
7. Episode
8. Footer


******************************/

/***********
1. Fonts
***********/

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background: #FFFFFF;
	color: #a5a5a5;
}
div
{
	display: block;
	position: relative;
 box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 1.875;
	font-weight: 300;
	color: rgba(0,0,0,0.5);
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #ffa07f;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a
{
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FFFFFF;
	background: #ffa07f;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: #ff3500;
	color: #FFFFFF;
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
	line-height: 1.2;
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
img
{
	max-width: 100%;
}
button:active
{
	outline: none;
}
.style_form-control__YssAZ
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.style_clear__1IY7O
{
	clear: both;
}
.style_clearfix__1gkwf::before, .style_clearfix__1gkwf::after
{
	content: "";
	display: table;
}
.style_clearfix__1gkwf::after
{
	clear: both;
}
.style_clearfix__1gkwf
{
	zoom: 1;
}
.style_float_left__3PVp5
{
	float: left;
}
.style_float_right__28_Ik
{
	float: right;
}
.style_trans_200__4v6b1
{
	transition: all 200ms ease;
}
.style_trans_300__3Atep
{
	transition: all 300ms ease;
}
.style_trans_400__2alvc
{
	transition: all 400ms ease;
}
.style_trans_500__3yU2F
{
	transition: all 500ms ease;
}
.style_fill_height__3PcrJ
{
	height: 100%;
}
.style_super_container__3-fxe
{
	width: 100%;
	overflow: hidden;
}
.style_prlx_parent__3Uc9X
{
	overflow: hidden;
}
.style_prlx__2Qmy7
{
	height: 130% !important;
}
.style_parallax-window__3aI8f
{
    min-height: 400px;
    background: transparent;
}
.style_parallax_background__30jRt
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.style_background_image__3mgMN
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.style_nopadding__2DEf6
{
	padding: 0px !important;
}
.style_owl-carousel__-ig_V,
.style_owl-carousel__-ig_V .style_owl-stage-outer__acZ7k,
.style_owl-carousel__-ig_V .style_owl-stage__3Lpua,
.style_owl-carousel__-ig_V .style_owl-item__2cmLS
{
	height: 100%;
}

.style_page_nav_row__2W2-p
{
	margin-top: 100px;
}
.style_page_nav__1nT0A ul li
{
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: solid 2px #96979b;
	transition: all 200ms ease;
}
.style_page_nav__1nT0A ul li:not(:last-of-type)
{
	margin-right: 8px;
}

.style_page_nav__1nT0A ul li button
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 46px;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	color: #96979b;
}
.style_page_nav__1nT0A ul li button.style_thumbsDown__1ZLAV{
	line-height: 50px;
}
.style_page_nav__1nT0A ul li:hover,
.style_page_nav__1nT0A ul li.style_active__1FI_1
{
	background: #ff3500;
	border-color: #ff3500;
}
.style_page_nav__1nT0A ul li.style_active__1FI_1 button,
.style_page_nav__1nT0A ul li:hover button
{
	color: #FFFFFF;
}
.style_page_nav__1nT0A ul li:last-child a i
{
	font-size: 20px;
	line-height: 27px;
	transform: translateX(2px);
}

.style_page_nav__1nT0A ul p
{
	display: block;
	margin-left: 10px;
	margin-right: 10px;
	line-height: 46px;
	text-align: center;
	font-size: 20px;
	font-weight: 700;
	color: #96979b;
}
.style_slide__5WFh-
{
	height: 100%;
}
.style_button_border__GSL8V
{
	display: inline-block;
	height: 42px;
	border: solid 1px #FFFFFF;
	border-radius: 21px;
	background: transparent;
}
.style_button_border__GSL8V a
{
	display: block;
	position: relative;
	height: 100%;
	padding-left: 28px;
	padding-right: 31px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 40px;
	border-radius: 21px;
}
.style_button_border__GSL8V:hover
{
	background: #FFFFFF;
}
.style_button_border__GSL8V:hover a
{
	color: #ff3500;
}
.style_button_fill__3fq-g
{
	display: inline-block;
	height: 42px;
	background: #ff3500;
	border-radius: 21px;
	transition: all 200ms ease;
}
.style_button_fill__3fq-g:hover
{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
}
.style_button_fill__3fq-g a
{
	display: block;
	width: 100%;
	height: 100%;
	padding-left: 25px;
	padding-right: 25px;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
}
.style_section_title__1PKjL
{
	font-size: 24px;
	color: #2e3038;
	font-weight: 500;
}
.style_tags__puL0s
{
	position: absolute;
	top: 13px;
	left: 11px;
}
.style_tags__puL0s ul li
{
    display: inline-block;
    height: 20px;
    background: #ff3500;
    border-radius: 3px;
    margin-bottom: 5px;
    transition: all 200ms ease;
}
.style_tags__puL0s ul li:not(:last-of-type)
{
    margin-right: 10px;
}
.style_tags__puL0s ul li:hover
{
	background: #2e3038;
}
.style_tags__puL0s ul li a
{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 12.73px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: 500;
    padding-left: 6px;
    padding-right: 7px;
}
div.style_top-loading-bar__16h3H
{
	z-index:9999999;
	position:fixed;
	top:0;
	left:0;
	height:2px;
	width:100%
}

/*********************************
3. Header
*********************************/

.style_header__3644K
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	z-index: 100;
}
.style_header__3644K.style_scrolled__NxHtx
{
	background: rgba(0,0,0,0.63);
}
.style_header_content__1v1gG
{
	width: 100%;
	height: 86px;
}
.style_header__3644K.style_scrolled__NxHtx .style_header_content__1v1gG
{
	height: 70px;
}
.style_logo__2u-k9
{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 33px;
	height: 51px;
	z-index: 1;
}
.style_logo__2u-k9 a
{
	display: block;
	width: 100%;
	height: 100%;
	font-size: 30px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_logo__2u-k9 a span
{
	font-weight: 100;
	margin-right: 5px;
}
.style_logo__2u-k9 a img
{
	vertical-align: top;
	margin-left: 5px;
}
.style_main_nav__3uats ul li:not(:last-of-type)
{
	margin-right: 40px;
}
.style_main_nav__3uats ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
}
.style_main_nav__3uats ul li a:hover
{
	color: #ff3000;
}
.style_main_nav__3uats ul li a.style_active__1FI_1
{
	color: #ff3000;
}
.style_header_right__1jTjh
{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 27px;
}
.style_submit__1Wqml
{
	height: 32px;
	border-radius: 16px;
	margin-right: 19px;
	transition: all 200ms ease;
}
.style_submit__1Wqml a
{
	display: block;
	height: 100%;
	line-height: 30px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	padding-left: 20px;
	padding-right: 20px;
}
.style_submit__1Wqml:hover
{
	border-color: #ff3000;
}
.style_submit__1Wqml:hover a
{
	color: #ff3000;
}
.style_hamburger__1yQ6v
{
	display: none;
	margin-left: 20px;
	cursor: pointer;
}
.style_hamburger__1yQ6v i
{
	font-size: 28px;
	color: #FFFFFF;
}

/*********************************
4. Menu
*********************************/

.style_menu__3Jvc5
{
	position: fixed;
	left: 0;
	top: -100vh;
	width: 100vw;
	height: 100vh;
	padding-right: 70px;
	padding-top: 200px;
	background: #1f2128;
	z-index: 99;
	transition: all 800ms cubic-bezier(.88,.31,.65,.91);
}
.style_menu__3Jvc5.style_active__1FI_1
{
	top: 0;
}
.style_menu_content__1xrJX
{
	width: 100%;
	height: 100%;
}
.style_menu_nav_list__1CyxB li
{
	transform: translateY(-50px);
	visibility: hidden;
	opacity: 0;
	transition: all 800ms ease;
}
.style_menu_nav_list__1CyxB li:first-child
{
	transition-delay: 400ms;
}
.style_menu_nav_list__1CyxB li:nth-child(2)
{
	transition-delay: 500ms;
}
.style_menu_nav_list__1CyxB li:nth-child(3)
{
	transition-delay: 600ms;
}
.style_menu_nav_list__1CyxB li:nth-child(4)
{
	transition-delay: 700ms;
}
.style_menu_nav_list__1CyxB li:nth-child(5)
{
	transition-delay: 800ms;
}
.style_menu_nav_list__1CyxB li:nth-child(6)
{
	transition-delay: 900ms;
}
.style_menu_nav_list__1CyxB li:nth-child(7)
{
	transition-delay: 1000ms;
}
.style_menu_nav_list__1CyxB li:nth-child(8)
{
	transition-delay: 1100ms;
}
.style_menu_nav_list__1CyxB li:nth-child(9)
{
	transition-delay: 1200ms;
}
.style_menu__3Jvc5.style_active__1FI_1 .style_menu_nav_list__1CyxB li
{
	transform: translateY(0px);
	visibility: visible;
	opacity: 1;
}
.style_menu_nav_list__1CyxB li a
{
	position: relative;
	font-size: 40px;
	color: #FFFFFF;
	font-weight: 400;
	line-height: 1.3;
	transition: all 400ms ease;
}
.style_menu_nav_list__1CyxB li a:hover
{
	color: #ff3500;
}
.style_menu_nav_list__1CyxB li a.style_active__1FI_1
{
	color: #ff3500;
}
.style_menu_extra__FAzyW
{
	position: absolute;
	left: 0;
	bottom: 15px;
	width: 100%;
}
.style_menu_submit__10ut5
{
	display: none;
	margin-bottom: 20px;
}
.style_menu_submit__10ut5 a
{
	white-space: nowrap;
	line-height: 1.1;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	transition: all 200ms ease;
}
.style_menu_submit__10ut5 a:hover
{
	color: #ff3500;
}
.style_menu__3Jvc5 .style_social__1mXcM
{
	display: none;
}
.style_menu__3Jvc5 .style_social__1mXcM ul li:not(:first-child)
{
	margin-left: 8px;
}

/*********************************
5. Home
*********************************/

.style_home__36zhk
{
	height: 490px;
}
.style_home_container__4SId8
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 171px;
}
.style_home_content__JOKL5
{
	
}
.style_home_title__3wf71 h1
{
	font-size: 36px;
	color: #FFFFFF;
	font-weight: 700;
}
.style_home_subtitle__CV8LX
{
	font-size: 18px;
	font-weight: 300;
	color: #FFFFFF;
	margin-top: 12px;
}
.style_home_player_container__1Kzpn
{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding-bottom: 27px;
}
.style_player__3O7BN
{
	width: 100%;
	border-radius: 3px;
	overflow: hidden;
}
.style_player_content__DgaE_
{
	width: 100%;
	padding-left: 12px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.style_single_player_container__Aw5TG
{
	
}
.style_single_player__1pMOv
{
	width: 100%;
	height: 40px;
}
.style_show_info__34sIL
{
	margin-top: 29px;
}
.style_show_fav__Co4DN
{
	margin-left: -3px;
}
.style_show_fav_icon__26u1t
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.style_show_comments__1dCfw
{
	margin-left: 17px;
}
.style_show_comments__1dCfw a
{
	display: block;
}
.style_show_comments_icon__1rcGs
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.style_show_info_icon__3xwMF img
{
	max-height: 100%;
}
.style_show_info_icon__3xwMF svg
{
	max-width: 100%;
	max-height: 100%;
}
.style_svg__1gYo4 path, .style_svg__1gYo4 rect, .style_svg__1gYo4 polygon
{
    fill: #7b7b7b;
    transition: all 200ms ease;
}
.style_show_fav_icon__26u1t.style_active__1FI_1 .style_svg__1gYo4 path,
.style_show_fav_icon__26u1t:hover .style_svg__1gYo4 path,
.style_show_comments__1dCfw:hover .style_svg__1gYo4 path
{
	fill: #ff3500;
}
.style_show_fav_count__FxAo2,
.style_show_comments_count__2TYra
{
	font-size: 16px;
	font-weight: 500;
	color: #5e5f62;
	margin-left: 7px;
	margin-bottom: 6px;
}
.style_show_comments__1dCfw:hover .style_show_comments_count__2TYra
{
	color: #ff3500;
}

/*********************************
6. Sidebar
*********************************/

.style_sidebar__33N5d
{
	width: 100%;
	z-index: 1;
	margin-top: 53px;
}
.style_sidebar_title__2xV4A
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.style_episode_image_container__21oDh
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}
.style_episode_image__3bDSq
{
	width: 100%;
	max-width: 300px;
	border-radius: 3px;
	overflow: hidden;
	margin-top: -146px;
}
.style_sidebar_list__24NHa
{
	
}
.style_sidebar_list__24NHa ul
{
	margin-top: 19px;
}
.style_sidebar_list__24NHa ul li:not(:last-of-type)
{
	margin-bottom: 3px;
}
.style_sidebar_list__24NHa ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #5e5f62;
}
.style_sidebar_list__24NHa ul li a:hover
{
	color: #ff3500;
}
.style_sidebar_tags__73p7p
{
	margin-top: 38px;
}
.style_sidebar__33N5d .style_tags__puL0s
{
	position: relative;
	top: auto;
	left: auto;
	margin-top: 24px;
}
.style_sidebar_archive__2ZFSl
{
	margin-top: 30px;
}
.style_dropdown__-_EJ4
{
	width: 100%;
	background: #f7f7f7;
	border-radius: 3px;
	margin-top: 24px;
}
.style_dropdown_selected__1UFmb
{
	position: relative;
	width: 100%;
	height: 32px;
	cursor: pointer;
	padding-left: 10px;
	padding-right: 15px;
	font-size: 14px;
	font-weight: 400;
	color: #2e3038;
}
.style_dropdown_selected__1UFmb i
{
	font-size: 12px;
	color: #2e3038;
}
.style_dropdown__-_EJ4 > ul > li > ul
{
	position: absolute;
	right: 0;
	top: 100%;
	width: 100%;
	padding-top: 15px;
	background: #FFFFFF;
	visibility: hidden;
	opacity: 0;
	transition: all 200ms ease;
}
.style_dropdown__-_EJ4 > ul > li > ul li
{
	padding-left: 10px;
	padding-right: 15px;
	padding-bottom: 7px;
}
.style_dropdown__-_EJ4 > ul > li > ul li:not(:last-of-type)
{
	margin-bottom: 8px;
	border-bottom: solid 1px rgba(0,0,0,0.05);
}
.style_dropdown__-_EJ4 > ul > li > ul li a
{
	display: block;
	font-size: 14px;
	font-weight: 400;
	color: #2e3038;
}
.style_dropdown__-_EJ4 > ul > li > ul li a:hover
{
	color: #ff3500;
}
.style_dropdown_selected__1UFmb:hover > ul
{
	visibility: visible;
	opacity: 1;
}

/*********************************
7. Episode
*********************************/

.style_episode_container__5g7gM
{
	background: #FFFFFF;
	padding-top: 89px;
	padding-bottom: 99px;
}
.style_intro_title__2APFa
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
}
.style_intro_text__h3UKo
{
	margin-top: 28px;
}
.style_guests__2JRRL
{
	margin-top: 53px;
}
.style_guests_container__2hDE2
{
	margin-top: 40px;
}

.style_songNotAvailable__h6dso{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top:90px; 
}

.style_songNotAvailable_Image__O_Xhy{
    width: 50%;
	height: auto;
	text-align: center;
}

.style_songNotAvailable_Text__gjEOP{
    color: rgb(0, 0, 0);
    font-size: 35px;
    margin-top: 20px;
	text-align: center;
}

.style_AgreeOrDisagree__hm0Yy{
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.style_AgreeOrDisagree__hm0Yy > div{
	margin-top: 10px;
	margin-bottom: 10px;
}

.style_Loading__3F3Z2{
	text-align: center;
}

.style_Loading__3F3Z2 img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}

.style_body__1YlXy{
	overflow: hidden;
}
.style_home__1OWH2
{
	height: 830px;
}

.style_home_container__24GBX
{
	position: absolute;
	top: 30%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.style_background_image__38DC4
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.style_tags__1vf5P ul li
{
	display: inline-block;
	height: 20px;
	background: #ff3500;
	border-radius: 3px;
	margin-bottom: 5px;
	transition: all 200ms ease;
}
.style_tags__1vf5P ul li:hover
{
	background: #2e3038;
}
.style_tags__1vf5P ul li:not(:last-of-type)
{
	margin-right: 10px;
}
.style_tags__1vf5P ul li a
{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	font-size: 12.73px;
	line-height: 20px;
	color: #FFFFFF;
	font-weight: 500;
	padding-left: 6px;
	padding-right: 7px;
}
.style_home_title__2lovY
{
	margin-top: 12px;
}
.style_home_title__2lovY h1
{
	font-size: 36px;
	color: #FFFFFF;
	font-weight: 500;
}
.style_home_subtitle__3u8RF
{
	font-size: 18px;
	font-weight: 300;
	color: #FFFFFF;
	line-height: 1.5;
	margin-top: 14px;
}
.style_track__1ICo8
{
	max-height: 166px;
}
.style_track_home__2PFga
{
	margin-top: 28px;
}
.style_home_button__3-aP5
{
	margin-top: 30px;
}
.style_track_info__bGz6O
{
	margin-top: 19px;
}
.style_track_info__bGz6O ul li a,
.style_track_info__bGz6O ul li
{
	font-size: 12px;
	color: #FFFFFF;
	font-weight: 300;
	line-height: 1.1;
}
.style_track_info__bGz6O ul li a:hover
{
	color: #ff3500;
}
.style_track_info__bGz6O ul li:not(:last-of-type)::after
{
	display: inline-block;
	content: '|';
	margin-left: 12px;
	margin-right: 12px;
}

/*********************************
6. Shows
*********************************/

.style_shows__1uH3V
{
	/* background: #FFFFFF; */
	background: #2e3038;
	padding-top: 93px;
	padding-bottom: 99px;
}
.style_shows_title__27iaI
{
	font-size: 24px;
	color: #FFFFFF;
	/* color: #1f2128; */
	font-weight: 500;
}
.style_shows_row__ApqzC
{
	margin-top: 38px;
}
.style_shows_row__ApqzC > div[class^='col']
{
	margin-bottom: 50px;
}
.style_shows_2_button__3YQlt
{
	margin-top: 20px;
}
.style_shows_2_button__3YQlt:hover
{
	background: #FFFFFF;
}
.style_shows_2_button__3YQlt:hover a
{
	color: #ff3500;
}
.style_show__TVRi9
{
	border-radius: 3px;
	overflow: hidden;
	background: #f6f6f6;
}
.style_show_image__2sl95 a
{
	display: block;
	width: 100%;
	height: 100%;
}
.style_show_image__2sl95 img
{
	vertical-align: middle;
	max-width: 100%;
}
.style_show_image__2sl95 img:hover
{
 transition: all 200ms ease;
	filter: brightness(50%);
}
.style_show_content__LfBsT
{
	padding-top: 20px;
	padding-left: 31px;
	padding-bottom: 22px;
	padding-right: 30px;
}
.style_show_tags__38Sbb
{
	position: absolute;
	top: 24px;
	left: 24px;
}
.style_show_date__qb-yi a
{
	font-size: 14px;
	font-weight: 300;
	color: #2e3038;
}
.style_show_title__1tacz
{
	margin-top: 11px;
}
.style_show_title__1tacz a
{
	font-size: 18px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.5;
}
.style_show_title__1tacz a:hover,
.style_show_date__qb-yi a:hover
{
	color: #ff3500;
}
.style_show_info__3w601
{
	margin-top: 23px;
}
.style_show_fav__uGA9i
{
	margin-left: -3px;
}
.style_show_fav_icon__LdTmA
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.style_show_comments__3cJ3y
{
	margin-left: 17px;
}
.style_show_comments__3cJ3y a
{
	display: block;
}
.style_show_comments_icon__2-x79
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.style_show_info_icon__1Zu6C svg
{
	max-width: 100%;
	max-height: 100%;
}
.style_svg__2Zs2X path, .style_svg__2Zs2X rect, .style_svg__2Zs2X polygon
{
    fill: #7b7b7b;
    transition: all 200ms ease;
}
.style_show_fav_icon__LdTmA.style_active__36Jbu .style_svg__2Zs2X path,
.style_show_fav_icon__LdTmA:hover .style_svg__2Zs2X path,
.style_show_comments__3cJ3y:hover .style_svg__2Zs2X path
{
	fill: #ff3500;
}
.style_show_fav_count__1ceTu,
.style_show_comments_count__T3cLb
{
	font-size: 14px;
	font-weight: 500;
	color: #2e3038;
	margin-left: 7px;
	margin-bottom: 2px;
}
.style_show_comments__3cJ3y:hover .style_show_comments_count__T3cLb
{
	color: #ff3500;
}
.style_show_play_icon__-SYqy
{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.85);
	width: 50px;
	height: 50px;
	pointer-events: none;
	transition: all 400ms ease;
}
.style_show_image__2sl95:hover .style_show_play_icon__-SYqy
{
	transform: translate(-50%, -50%) scale(1);
}



/*********************************
8. Milestones
*********************************/

.style_milestones__1fBeY
{
	padding-top: 65px;
	padding-bottom: 112px;
}
.style_milestones_row__h9dZH
{
	margin-top: 69px;
}
.style_milestones_row__h9dZH > div[class^='col']
{
	margin-bottom: 60px;
}
.style_milestone_content__2BsjL
{
	height: 80px;
}
.style_milestone_icon__1DKgG
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100%;
	max-width: none;
	opacity: 0.25;
	pointer-events: none;
}
.style_milestone_icon__1DKgG i
{
	color: #FFFFFF;
	font-size: 5.5em;
}
.style_milestone_counter__i500R
{
	font-size: 24px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 80px;
}
.style_milestone_title__3wu1Y
{
	font-size: 18px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 0.75;
	margin-top: 33px;
}
/*********************************
9. Newsletter
*********************************/
.style_newsletter__3-DCr
{
	padding-top: 74px;
	padding-bottom: 166px;
}
.style_newsletter_text__Crhlo
{
	max-width: 510px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 18px;
}
.style_newsletter_text__Crhlo p
{
	font-size: 16px;
	font-weight: 300;
	color: #FFFFFF;
	line-height: 1.5;
}
.style_newsletter_row__2_pvO
{
	margin-top: 63px;
}
.style_newsletter_form_container__ftKbb
{
	width: 100%;
}
.style_newsletter_form__1eSG6
{
	display: block;
}
.style_newsletter_input__COCbC
{
	display: block;
	width: calc(100% - 260px);
	height: 54px;
	background: #FFFFFF;
	border: none;
	outline: none;
	border-radius: 27px;
	font-size: 18px;
	color: #1f2128;
	padding-left: 30px;
}
.style_newsletter_button__cLr6t
{
	width: 230px;
	height: 54px;
	border: none;
	outline: none;
	border-radius: 27px;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	cursor: pointer;
}

.style_intro__1vtST
{
	background: #FFFFFF;
	padding-top: 97px;
	padding-bottom: 92px;
}
.style_intro_text__3t6Dd
{
	margin-top: 13px;
}
.style_intro_button__oYnsL
{
	width: 161px;
	margin-top: 29px;
}

.style_button_fill__1HBXm
{
	display: inline-block;
	height: 42px;
	background: #ff3500;
	border-radius: 21px;
	transition: all 200ms ease;
}

.style_button_fill__1HBXm:hover
{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
}

.style_button_fill__1HBXm a
{
	display: block;
	width: 100%;
	height: 100%;
	padding-left: 25px;
	padding-right: 25px;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
}

.style_section_title__1wu3C h1
{
	font-size: 36px;
	color: #1f2128;
	font-weight: 500;
}
.style_section_title__1wu3C h1 span
{
	font-weight: 600;
}
.style_section_title__1wu3C.style_light__326s_ h1
{
	color: #FFFFFF;
}

.style_Loading__21DwB{
	text-align: center;
}

.style_Loading__21DwB img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}

.style_expiredEmail__LU6-Q, .style_InvalidToken__3ZjKR, .style_VerifiedUser__DPVWT{
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.style_expiredEmail_img__1qHiZ img, .style_InvalidToken_img__3CMoB img, .style_image__2pLJe img{
	height: auto;
	width: 350px;
	margin-top: 100px;
}

.style_expiredEmail_txt__2Y-3E, .style_InvalidToken_txt__kQ-Cw, .style_text__35Xbu{
	color: #111;
	font-size: 20px;
	text-align: center;
}

.style_requestNewEmail__PabbJ div img.style_active__36Jbu{
	height: auto;
	width: 20px;
	margin-right: 10px;
}

.style_requestNewEmail__PabbJ div img.style_noactive__E3WTy{
	height: auto;
	width: 20px;
	margin-right: 10px;
	display: none;
}

.style_requestNewEmail__PabbJ div{
	background-color: #ff3500;
	padding: 10px;
	color: #fff;
	margin-top: 10px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border-radius: 20px;
}

.style_requestNewEmail__PabbJ div:hover{
	background-color: #2e3038;
	transition: 0.5s;
	cursor: pointer;
}

.style_emailNotSent__1AXsG{
	display: none;
}

.style_emailSent__Y9XhH{
	width: 20px;
	margin-right: 10px;
}
.style_Error__3upoO{
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
}

.style_image__fSru2 img{
	height: auto;
	width: 500px;
	margin-top: 26px;
}

.style_backHome__1bprC a{
	background-color: #ff3500;
	color: #fff;
	border-radius: 20px;
	padding: 10px;
}

.style_backHome__1bprC a:hover{
	background-color: #2e3038;
	transition: 0.5s;
}

.style_body__1e3Fg{
	overflow: hidden;
}

.style_content__11pew{
	height: 800px;
	background-color: #4f518489; 
}


.style_ManageUser__3SC8B{
}


.style_home__1drOu
{
	background-color: #4a4aff68; 
	height: 346px;
}
.style_home_container__xofDf
{
	width: 100%;
	height: 100%;
}
.style_home_title__1q4Oq
{
	
}
.style_home_title__1q4Oq h1
{
	font-size: 5vw;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}

.style_table__1yXwK{
	height: 500px;
}

.style_block_button__1eNOm{
	background-color: rgb(255, 0, 0);
	padding: 10px;
	color: #fff;
	border-radius: 10px;
}

.style_block_button__1eNOm:hover{
	transition: 0.5s;
	background-color: rgba(255, 0, 0, 0.548);
}

.style_unblock_button__SgyxJ{
	background-color: rgb(0, 47, 255);
	padding: 10px;
	color: #fff;
	border-radius: 10px;
	margin-right: 10px;
}

.style_unblock_button__SgyxJ:hover{
	transition: 0.5s;
	background-color: rgba(0, 38, 255, 0.221);
}

.style_blockeduser__2ffjE{
	display: flex;
}


/*SearchBar*/
#style_SearchBar__3QgOq{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
#style_SearchBar__3QgOq .style_form__1ZF0D{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 80vw;
    height: 72px;
    border-radius: 10px;
    padding: 20px;
}

#style_SearchBar__3QgOq .style_form__1ZF0D input, button{
    outline: none;
    border: none;
    background-color: transparent;
}

#style_SearchBar__3QgOq .style_form__1ZF0D input{
    width: 100%;
}

#style_SearchBar__3QgOq.style_active__vZxxZ .style_form__1ZF0D{
    border-radius: 0px;
}

#style_SearchBar__3QgOq .style_filter__3uwVH select{
    width: 80px;
}

#style_searchresults__9OVan{
    background-color: #fff;
    width: 80vw;
    max-height: 35vh;
    overflow-y: auto;
    overflow-x:hidden;
}

#style_searchresults__9OVan > div{
    border-bottom: 1px solid rgb(216, 216, 216);
    padding: 10px;
    cursor: pointer;
    display: flex;
}

#style_searchresults__9OVan > div .style_imageResult__3o9lw{
    margin-right: 10px;
}

#style_searchresults__9OVan > div:hover{
    background-color: rgb(216, 216, 216);
    transition: 0.5s;
}


#style_searchresults__9OVan.style_noactive__1yfpV{
    visibility: hidden;
}

.style_isAlreadyClassified__METrA a, .style_classifyButton__2GRnu button{
    color: #fff;
    background-color: #ff3000;
    padding: 5px;
    text-decoration: underline;
    border-radius: 10px;
    margin-top: 10px;
}

.style_classifyButton__2GRnu button{
    text-decoration: none;
}
.style_isAlreadyClassified__METrA a:hover, .style_classifyButton__2GRnu button:hover{
    background-color: #ff6341;
}

.style_isAlreadyClassified__METrA p{
    color: black;
    font-weight: bold;
}

.style_radio__13Ruj{
	display: flex;
	margin-top: 10px;
}

.style_radio__13Ruj label{
	color: #111;
	font-weight: bold;
	margin-right: 5px;
}

.style_LogOutButton__10wfv{
	background-color: #ff0000;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.style_LogOutButton__10wfv:hover{
	background-color: #2e3038;
	transition: 0.2s;
}

.style_CancelLogOutButton__3MMxE{
	background-color: #bababa;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.style_CancelLogOutButton__3MMxE:hover{
	background-color: #a8a8a8;
}
.style_container__tcyNJ{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 600px;
}

.style_username__1DffA{
	color: #ff3500;
	font-weight: bold;
}

.style_body__1eLy2{
	overflow: hidden;
}

.style_changeP__oq4Um, .style_changeD__fMQbh{
	background-color: #ff3500;
	font-weight: bold;
	display: flex;
	padding: 10px;
	border-radius: 10px;
	margin-top: 10px;
}

.style_changeP__oq4Um:hover, .style_changeD__fMQbh:hover{
	transition: .5s;
	background-color: #ff33007a;
	cursor: pointer;
}

.style_changeP__oq4Um p, .style_changeD__fMQbh p{
	color: #fff;
	margin-left: 10px;
}

.style_imgC__29MDw{
	border-bottom: 1px solid #0000004a;
	margin-bottom: 10px;
}



.style_signUpForm__mNbi2{
	display: flex;
	flex-direction: column;
}

.style_signUpForm__mNbi2 span{
	font-weight: normal;
	color: #999;
	font-size: 12px;
}

.style_signUpForm__mNbi2 input{
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 2px;
	height: 45px;
	padding: 0 15px;
	font-size: 16px;
	outline: none;
}

.style_signUpForm__mNbi2 button{
	background-color: #ff3500;
	color: #ddd;
	font-size: 16px;
	height: 45px;
	outline: none;
	border: none;
	border-radius: 2px;
}

.style_signUpForm__mNbi2 button:hover{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
	transition: 0.5s;
}

#style_errorMessage__3kCmE, #style_errorMessage2__ewSB7{
	background-color: #e81a1a3c;
	padding: 10px;
	width: 100%;
	display: none;
	color: #ff0000;
}

#style_errorMessage__3kCmE.style_sucess__1wIOc{
	background-color: #43e81a3c;
	padding: 10px;
	width: 100%;
	display: none;
	color: #00ff2a;
}

.style_body__zj7Nj{
	overflow: hidden;
}

.style_form__4AzcF > form{
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 250px auto 0;
}


.style_signUpForm__lTlqZ{
	display: flex;
	flex-direction: column;
	width: 60%;
}

.style_signUpForm__lTlqZ span{
	font-weight: normal;
	color: #999;
	font-size: 12px;
}

.style_signUpForm__lTlqZ input{
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 2px;
	height: 45px;
	padding: 0 15px;
	font-size: 16px;
	outline: none;
}

.style_signUpForm__lTlqZ button{
	background-color: #ff3500;
	color: #ddd;
	font-size: 16px;
	height: 45px;
	outline: none;
	border: none;
	border-radius: 2px;
}

.style_signUpForm__lTlqZ button:hover{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
	transition: 0.5s;
}

.style_expiredEmail__1a7hc, .style_InvalidToken__hAVen, .style_VerifiedUser__1IpoW{
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.style_expiredEmail_img__UHNX5 img, .style_InvalidToken_img__xES6O img, .style_image__cbfLk img{
	height: auto;
	width: 350px;
	margin-top: 100px;
}

.style_expiredEmail_txt__1MeQY, .style_InvalidToken_txt__iaF3S, .style_text__1QZEJ{
	color: #111;
	font-size: 20px;
	text-align: center;
}



