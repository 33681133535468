
.tags
{
	position: absolute;
	top: 13px;
	left: 11px;
}
.tags ul li
{
    display: inline-block;
    height: 20px;
    background: #ff3500;
    border-radius: 3px;
    margin-bottom: 5px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.tags ul li:not(:last-of-type)
{
    margin-right: 10px;
}
.tags ul li:hover
{
	background: #2e3038;
}
.tags ul li a
{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 12.73px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: 500;
    padding-left: 6px;
    padding-right: 7px;
}


/*********************************
3. Header
*********************************/

.header
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	z-index: 100;
}
.header.scrolled
{
	background: rgba(0,0,0,0.63);
}
.header_content
{
	width: 100%;
	height: 86px;
}
.header.scrolled .header_content
{
	height: 70px;
}
.logo
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 33px;
	height: 51px;
	z-index: 1;
}
.logo a
{
	display: block;
	width: 100%;
	height: 100%;
	font-size: 30px;
	font-weight: 500;
	color: #FFFFFF;
}
.logo a span
{
	font-weight: 100;
	margin-right: 5px;
}
.logo a img
{
	vertical-align: top;
	margin-left: 5px;
}
.main_nav ul li:not(:last-of-type)
{
	margin-right: 40px;
}
.main_nav ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
}
.main_nav ul li a:hover
{
	color: #ff3000;
}
.main_nav ul li a.active
{
	color: #ff3000;
}
.header_right
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 27px;
}
.submit
{
	height: 32px;
	border-radius: 16px;
	margin-right: 19px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.submit a
{
	display: block;
	height: 100%;
	line-height: 30px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	padding-left: 20px;
	padding-right: 20px;
}
.submit:hover
{
	border-color: #ff3000;
}
.submit:hover a
{
	color: #ff3000;
}
.hamburger
{
	display: none;
	margin-left: 20px;
	cursor: pointer;
}
.hamburger i
{
	font-size: 28px;
	color: #FFFFFF;
}

/*********************************
4. Menu
*********************************/

.menu
{
	position: fixed;
	left: 0;
	top: -100vh;
	width: 100vw;
	height: 100vh;
	padding-right: 70px;
	padding-top: 200px;
	background: #1f2128;
	z-index: 99;
	-webkit-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-moz-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-ms-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-o-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	transition: all 800ms cubic-bezier(.88,.31,.65,.91);
}
.menu.active
{
	top: 0;
}
.menu_content
{
	width: 100%;
	height: 100%;
}
.menu_nav_list li
{
	-webkit-transform: translateY(-50px);
	-moz-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	-o-transform: translateY(-50px);
	transform: translateY(-50px);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}
.menu_nav_list li:first-child
{
	transition-delay: 400ms;
}
.menu_nav_list li:nth-child(2)
{
	transition-delay: 500ms;
}
.menu_nav_list li:nth-child(3)
{
	transition-delay: 600ms;
}
.menu_nav_list li:nth-child(4)
{
	transition-delay: 700ms;
}
.menu_nav_list li:nth-child(5)
{
	transition-delay: 800ms;
}
.menu_nav_list li:nth-child(6)
{
	transition-delay: 900ms;
}
.menu_nav_list li:nth-child(7)
{
	transition-delay: 1000ms;
}
.menu_nav_list li:nth-child(8)
{
	transition-delay: 1100ms;
}
.menu_nav_list li:nth-child(9)
{
	transition-delay: 1200ms;
}
.menu.active .menu_nav_list li
{
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
	visibility: visible;
	opacity: 1;
}
.menu_nav_list li a
{
	position: relative;
	font-size: 40px;
	color: #FFFFFF;
	font-weight: 400;
	line-height: 1.3;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.menu_nav_list li a:hover
{
	color: #ff3500;
}
.menu_nav_list li a.active
{
	color: #ff3500;
}
.menu_extra
{
	position: absolute;
	left: 0;
	bottom: 15px;
	width: 100%;
}
.menu_submit
{
	display: none;
	margin-bottom: 20px;
}
.menu_submit a
{
	white-space: nowrap;
	line-height: 1.1;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_submit a:hover
{
	color: #ff3500;
}
.menu .social
{
	display: none;
}
.menu .social ul li:not(:first-child)
{
	margin-left: 8px;
}

/*********************************
5. Home
*********************************/

.home
{
	height: 346px;
}
.home_container
{
	width: 100%;
	height: 100%;
}
.home_title
{
	
}
.home_title h1
{
	font-size: 60px;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}

/*********************************
6. Sidebar
*********************************/

.sidebar
{
	width: 100%;
	margin-top: -3px;
	z-index: 1;
}
.sidebar_title
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.search_form
{
	display: block;
	position: relative;
	width: 100%;
	margin-top: 22px;
}
.search_input
{
	width: 100%;
	height: 32px;
	padding-left: 18px;
	background: #f7f7f7;
	border: none;
	outline: none;
	border-radius: 16px;
}
.search_input::-webkit-input-placeholder
{
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
}
.search_input:-moz-placeholder
{
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
}
.search_input::-moz-placeholder
{
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
} 
.search_input:-ms-input-placeholder
{ 
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
}
.search_input::input-placeholder
{
	font-size: 13px !important;
	font-weight: 300 !important;
	font-style: italic;
	color: #2e3038 !important;
}
.search_button
{
	position: absolute;
	top: 0;
	right: 5px;
	width: 32px;
	height: 32px;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}
.sidebar_list
{
	margin-top: 36px;
}
.sidebar_list ul
{
	margin-top: 19px;
}
.sidebar_list ul li:not(:last-of-type)
{
	margin-bottom: 3px;
}
.sidebar_list ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #5e5f62;
}
.sidebar_list ul li a:hover
{
	color: #ff3500;
}
.sidebar_tags
{
	margin-top: 38px;
}
.sidebar .tags
{
	position: relative;
	top: auto;
	left: auto;
	margin-top: 24px;
}
.sidebar_archive
{
	margin-top: 30px;
}
.dropdown
{
	width: 100%;
	background: #f7f7f7;
	border-radius: 3px;
	margin-top: 24px;
}
.dropdown_selected
{
	position: relative;
	width: 100%;
	height: 32px;
	cursor: pointer;
	padding-left: 10px;
	padding-right: 15px;
	font-size: 14px;
	font-weight: 400;
	color: #2e3038;
}
.dropdown_selected i
{
	font-size: 12px;
	color: #2e3038;
}
.dropdown > ul > li > ul
{
	position: absolute;
	right: 0;
	top: 100%;
	width: 100%;
	padding-top: 15px;
	background: #FFFFFF;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.dropdown > ul > li > ul li
{
	padding-left: 10px;
	padding-right: 15px;
	padding-bottom: 7px;
}
.dropdown > ul > li > ul li:not(:last-of-type)
{
	margin-bottom: 8px;
	border-bottom: solid 1px rgba(0,0,0,0.05);
}
.dropdown > ul > li > ul li a
{
	display: block;
	font-size: 14px;
	font-weight: 400;
	color: #2e3038;
}
.dropdown > ul > li > ul li a:hover
{
	color: #ff3500;
}
.dropdown_selected:hover > ul
{
	visibility: visible;
	opacity: 1;
}

/*********************************
7. Blog
*********************************/

.blog
{
	background: #FFFFFF;
	padding-top: 109px;
	padding-bottom: 106px;
}
.blog_post:not(:last-child)
{
	margin-bottom: 77px;
}
.blog_post_image
{
	width: 42.5%;
	-webkit-flex: 0 0 auto;
	flex: 0 0 auto;
}
/* .blog_post_image img:hover
{
	-webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
	filter: brightness(50%);
} */
.blog_post_content
{
	width: 57.5%;
	-webkit-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-left: 30px;
}
.blog_post_date
{
	position: absolute;
	left: -6px;
	top: -9px;
	width: 161px;
	height: 42px;
	border-radius: 21px;
	background: #ff3500;
}
.blog_post_date a
{
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
}
.blog_post_title
{
	margin-top: -4px;
}
.blog_post_title a
{
	font-size: 24px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.2;
}
.blog_post_author
{
	margin-top: 5px;
}
.blog_post_author,
.blog_post_author a
{
	font-size: 14px;
	font-weight: 300;
	color: #2e3038;
}
.blog_post_title a:hover,
.blog_post_author a:hover
{
	color: #ff3500;
}
.blog_post_text
{
	margin-top: 38px;
}
.blog_post_link
{
	margin-top: 33px;
}
.blog_post_link a
{
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: #ff3500;
}
.blog_post_link a::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	background: #ff3500;
	content: '';
}
.page_nav_row
{
	margin-top: 100px;
}
.page_nav ul li
{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: solid 2px #96979b;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.page_nav ul li:not(:last-of-type)
{
	margin-right: 8px;
}
.page_nav ul li a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 28px;
	text-align: center;
	font-size: 14px;
	font-weight: 700;
	color: #96979b;
}
.page_nav ul li:hover,
.page_nav ul li.active
{
	background: #ff3500;
	border-color: #ff3500;
}
.page_nav ul li.active a,
.page_nav ul li:hover a
{
	color: #FFFFFF;
}
.page_nav ul li:last-child a i
{
	font-size: 20px;
	line-height: 27px;
	-webkit-transform: translateX(2px);
	-moz-transform: translateX(2px);
	-ms-transform: translateX(2px);
	-o-transform: translateX(2px);
	transform: translateX(2px);
}

.notFound{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.notFound img{
	height: 30em;
}

.notFound h1{
	color: #ff3000;
	font-size: 30px;
}


@media only screen and (max-width: 991px)
{
	.sidebar_col
	{
		margin-top: 100px;
	}
}


/************
10. 767px
************/

@media only screen and (max-width: 767px)
{
	.blog_post_image
	{
		width: 100%;
	}
	.blog_post_content
	{
		width: 100%;
		padding-left: 0px;
		padding-top: 25px;
	}
	.blog_post_text
	{
		margin-top: 33px;
	}
}

#SearchResults.active{
	margin-bottom: 30px;
	padding: 10px;
	border-bottom: 1px solid #96979b;
}

#SearchResults.noactive{
	display: none;
}
/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	.sidebar_list ul li a
	{
		font-size: 14px;
	}
	.blog_post_date
	{
		width: 129px;
		height: 35px;
	}
	.blog_post_date a
	{
		font-size: 12px;
		line-height: 35px;
	}
}


.delete_button{
	width: 100px;
	height: 40px;
	margin-top: 5px;
	background-color: #ff4f4f;
	border-radius: 10px;
	color: #FFFFFF;
}

.delete_button:hover{
	transition: 0.5s;
	background-color: #c30000;
}



.LogOutButton{
	background-color: #ff0000;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.LogOutButton:hover{
	background-color: #2e3038;
	transition: 0.2s;
}

.CancelLogOutButton{
	background-color: #bababa;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.CancelLogOutButton:hover{
	background-color: #a8a8a8;
}


.Loading{
	text-align: center;
}

.Loading img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}
