.home {
	height: 346px;
}

.home_container {
	width: 100%;
	height: 100%;
}

.home_title {}

.home_title h1 {
	font-size: 60px;
	color: #FFFFFF;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}


.custom-popover {
	--bs-popover-max-width: 200px;
	--bs-popover-border-color: var(--bs-primary);
	--bs-popover-header-bg: var(--bs-primary);
	--bs-popover-header-color: var(--bs-white);
	--bs-popover-body-padding-x: 1rem;
	--bs-popover-body-padding-y: .5rem;
}


.about {
	background: #FFFFFF;
	padding-top: 103px;
}

.about_content {
	width: calc(100% - 390px);
	margin-top: 35px;
}

.about_image {
	width: 390px;
	padding-right: 30px;
}

.about_text {
	margin-top: 36px;
}

.about .social {
	margin-top: 40px;
}

.about .social ul li {
	width: 48px;
	height: 48px;
	border-color: #bba9ab;
}

.social ul li:not(:first-child) {
	margin-left: 0;
}

.about .social ul li:not(:last-of-type) {
	margin-right: 6px;
}

.about .social ul li a i {
	font-size: 22px;
	color: #bba9ab;
	line-height: 46px;
}

.about .social ul li:hover {
	border-color: #ff3500;
}

.about .social ul li:hover a i {
	color: #ff3500;
}


.guests {
	margin-top: 53px;
}

.guests_container {
	margin-top: 40px;
}

.guest_container {
	width: calc(100% / 3);
}

.guest {
	max-width: 166px;
	border-radius: 3px;
	overflow: hidden;
	margin-bottom: 40px;
}

.guest_content {
	padding-top: 29px;
}

.guest_name a {
	font-size: 18px;
	font-weight: 500;
	color: #ff3500;
	line-height: 1.2;
}

.guest_name a:hover {
	color: rgba(0, 0, 0, 1);
}

.guest_title {
	font-size: 14px;
	font-weight: 500;
	color: #b1b2b4;
	margin-top: 2px;
}

.section_title {
	font-size: 24px;
	color: #2e3038;
	font-weight: 500;
}

.section_title h1 {
	font-size: 36px;
	color: #1f2128;
	font-weight: 500;
}

.section_title h1 span {
	font-weight: 600;
}

.section_title.light h1 {
	color: #FFFFFF;
}

.milestones {
	padding-top: 65px;
	padding-bottom: 112px;
}

.milestones_row {
	margin-top: 69px;
}

.milestones_row>div[class^='col'] {
	margin-bottom: 60px;
}

.milestone {}

.milestone_content {
	height: 80px;
}

.milestone_icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100%;
	max-width: none;
	opacity: 0.3;
	pointer-events: none;
}

.milestone_icon i {
	color: #FFFFFF;
	font-size: 5.5em;
}

.milestone_counter {
	font-size: 24px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 80px;
}

.milestone_title {
	font-size: 18px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 0.75;
	margin-top: 33px;
}

@media only screen and (max-width: 991px) {
	.submit {
		display: none;
	}

	.menu_submit {
		display: block;
	}

	.about_content {
		width: 100%;
		margin-top: 0;
	}

	.about_image {
		margin-top: 60px;
	}
}