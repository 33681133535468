#SearchBar .form{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 80vw;
    height: 72px;
    border-radius: 30px;
    padding: 20px;
}

#SearchBar .form input, button{
    outline: none;
    border: none;
    background-color: transparent;
}

#SearchBar .form input{
    width: 100%;
}

#SearchBar.active .form{
    border-radius: 0px;
}

#SearchBar .filter select{
    width: 80px;
}

#searchresults{
    background-color: #fff;
    width: 80vw;
    max-height: 35vh;
    overflow-y: auto;
    overflow-x:hidden;
}

#searchresults > div{
    border-bottom: 1px solid rgb(216, 216, 216);
    padding: 10px;
    cursor: pointer;
    display: flex;
}

#searchresults > div .imageResult{
    margin-right: 10px;
}

#searchresults > div:hover{
    background-color: rgb(216, 216, 216);
    transition: 0.5s;
}


#searchresults.noactive{
    visibility: hidden;
}

.isAlreadyClassified a, .classifyButton button{
    color: #fff;
    background-color: #ff3000;
    padding: 5px;
    text-decoration: underline;
    border-radius: 10px;
    margin-top: 10px;
}

.classifyButton button{
    text-decoration: none;
}
.isAlreadyClassified a:hover, .classifyButton button:hover{
    background-color: #ff6341;
}

.isAlreadyClassified p{
    color: black;
    font-weight: bold;
}

