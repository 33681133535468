.container{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 600px;
}

.username{
	color: #ff3500;
	font-weight: bold;
}

.body{
	overflow: hidden;
}

.changeP, .changeD{
	background-color: #ff3500;
	font-weight: bold;
	display: flex;
	padding: 10px;
	border-radius: 10px;
	margin-top: 10px;
}

.changeP:hover, .changeD:hover{
	transition: .5s;
	background-color: #ff33007a;
	cursor: pointer;
}

.changeP p, .changeD p{
	color: #fff;
	margin-left: 10px;
}

.imgC{
	border-bottom: 1px solid #0000004a;
	margin-bottom: 10px;
}



.signUpForm{
	display: flex;
	flex-direction: column;
}

.signUpForm span{
	font-weight: normal;
	color: #999;
	font-size: 12px;
}

.signUpForm input{
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 2px;
	height: 45px;
	padding: 0 15px;
	font-size: 16px;
	outline: none;
}

.signUpForm button{
	background-color: #ff3500;
	color: #ddd;
	font-size: 16px;
	height: 45px;
	outline: none;
	border: none;
	border-radius: 2px;
}

.signUpForm button:hover{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
	transition: 0.5s;
}

#errorMessage, #errorMessage2{
	background-color: #e81a1a3c;
	padding: 10px;
	width: 100%;
	display: none;
	color: #ff0000;
}

#errorMessage.sucess{
	background-color: #43e81a3c;
	padding: 10px;
	width: 100%;
	display: none;
	color: #00ff2a;
}
