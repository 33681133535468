.body{
	overflow: hidden;
}
.home
{
	height: 830px;
}

.home_container
{
	position: absolute;
	top: 30%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.background_image
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.tags ul li
{
	display: inline-block;
	height: 20px;
	background: #ff3500;
	border-radius: 3px;
	margin-bottom: 5px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.tags ul li:hover
{
	background: #2e3038;
}
.tags ul li:not(:last-of-type)
{
	margin-right: 10px;
}
.tags ul li a
{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	font-size: 12.73px;
	line-height: 20px;
	color: #FFFFFF;
	font-weight: 500;
	padding-left: 6px;
	padding-right: 7px;
}
.home_title
{
	margin-top: 12px;
}
.home_title h1
{
	font-size: 36px;
	color: #FFFFFF;
	font-weight: 500;
}
.home_subtitle
{
	font-size: 18px;
	font-weight: 300;
	color: #FFFFFF;
	line-height: 1.5;
	margin-top: 14px;
}
.track
{
	max-height: 166px;
}
.track_home
{
	margin-top: 28px;
}
.home_button
{
	margin-top: 30px;
}
.track_info
{
	margin-top: 19px;
}
.track_info ul li a,
.track_info ul li
{
	font-size: 12px;
	color: #FFFFFF;
	font-weight: 300;
	line-height: 1.1;
}
.track_info ul li a:hover
{
	color: #ff3500;
}
.track_info ul li:not(:last-of-type)::after
{
	display: inline-block;
	content: '|';
	margin-left: 12px;
	margin-right: 12px;
}

/*********************************
6. Shows
*********************************/

.shows
{
	/* background: #FFFFFF; */
	background: #2e3038;
	padding-top: 93px;
	padding-bottom: 99px;
}
.shows_title
{
	font-size: 24px;
	color: #FFFFFF;
	/* color: #1f2128; */
	font-weight: 500;
}
.shows_row
{
	margin-top: 38px;
}
.shows_row > div[class^='col']
{
	margin-bottom: 50px;
}
.shows_2_button
{
	margin-top: 20px;
}
.shows_2_button:hover
{
	background: #FFFFFF;
}
.shows_2_button:hover a
{
	color: #ff3500;
}
.show
{
	border-radius: 3px;
	overflow: hidden;
	background: #f6f6f6;
}
.show_image a
{
	display: block;
	width: 100%;
	height: 100%;
}
.show_image img
{
	vertical-align: middle;
	max-width: 100%;
}
.show_image img:hover
{
	-webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
	filter: brightness(50%);
}
.show_content
{
	padding-top: 20px;
	padding-left: 31px;
	padding-bottom: 22px;
	padding-right: 30px;
}
.show_tags
{
	position: absolute;
	top: 24px;
	left: 24px;
}
.show_date a
{
	font-size: 14px;
	font-weight: 300;
	color: #2e3038;
}
.show_title
{
	margin-top: 11px;
}
.show_title a
{
	font-size: 18px;
	font-weight: 500;
	color: #2e3038;
	line-height: 1.5;
}
.show_title a:hover,
.show_date a:hover
{
	color: #ff3500;
}
.show_info
{
	margin-top: 23px;
}
.show_fav
{
	margin-left: -3px;
}
.show_fav_icon
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.show_comments
{
	margin-left: 17px;
}
.show_comments a
{
	display: block;
}
.show_comments_icon
{
	width: 26px;
	height: 26px;
	cursor: pointer;
}
.show_info_icon svg
{
	max-width: 100%;
	max-height: 100%;
}
.svg path, .svg rect, .svg polygon
{
    fill: #7b7b7b;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.show_fav_icon.active .svg path,
.show_fav_icon:hover .svg path,
.show_comments:hover .svg path
{
	fill: #ff3500;
}
.show_fav_count,
.show_comments_count
{
	font-size: 14px;
	font-weight: 500;
	color: #2e3038;
	margin-left: 7px;
	margin-bottom: 2px;
}
.show_comments:hover .show_comments_count
{
	color: #ff3500;
}
.show_play_icon
{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) scale(0.85);
	-moz-transform: translate(-50%, -50%) scale(0.85);
	-ms-transform: translate(-50%, -50%) scale(0.85);
	-o-transform: translate(-50%, -50%) scale(0.85);
	transform: translate(-50%, -50%) scale(0.85);
	width: 50px;
	height: 50px;
	pointer-events: none;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.show_image:hover .show_play_icon
{
	-webkit-transform: translate(-50%, -50%) scale(1);
	-moz-transform: translate(-50%, -50%) scale(1);
	-ms-transform: translate(-50%, -50%) scale(1);
	-o-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
}



/*********************************
8. Milestones
*********************************/

.milestones
{
	padding-top: 65px;
	padding-bottom: 112px;
}
.milestones_row
{
	margin-top: 69px;
}
.milestones_row > div[class^='col']
{
	margin-bottom: 60px;
}
.milestone_content
{
	height: 80px;
}
.milestone_icon
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100%;
	max-width: none;
	opacity: 0.25;
	pointer-events: none;
}
.milestone_icon i
{
	color: #FFFFFF;
	font-size: 5.5em;
}
.milestone_counter
{
	font-size: 24px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 80px;
}
.milestone_title
{
	font-size: 18px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 0.75;
	margin-top: 33px;
}
/*********************************
9. Newsletter
*********************************/
.newsletter
{
	padding-top: 74px;
	padding-bottom: 166px;
}
.newsletter_text
{
	max-width: 510px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 18px;
}
.newsletter_text p
{
	font-size: 16px;
	font-weight: 300;
	color: #FFFFFF;
	line-height: 1.5;
}
.newsletter_row
{
	margin-top: 63px;
}
.newsletter_form_container
{
	width: 100%;
}
.newsletter_form
{
	display: block;
}
.newsletter_input
{
	display: block;
	width: calc(100% - 260px);
	height: 54px;
	background: #FFFFFF;
	border: none;
	outline: none;
	border-radius: 27px;
	font-size: 18px;
	color: #1f2128;
	padding-left: 30px;
}
.newsletter_button
{
	width: 230px;
	height: 54px;
	border: none;
	outline: none;
	border-radius: 27px;
	color: #FFFFFF;
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	cursor: pointer;
}

.intro
{
	background: #FFFFFF;
	padding-top: 97px;
	padding-bottom: 92px;
}
.intro_text
{
	margin-top: 13px;
}
.intro_button
{
	width: 161px;
	margin-top: 29px;
}

.button_fill
{
	display: inline-block;
	height: 42px;
	background: #ff3500;
	border-radius: 21px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}

.button_fill:hover
{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
}

.button_fill a
{
	display: block;
	width: 100%;
	height: 100%;
	padding-left: 25px;
	padding-right: 25px;
	line-height: 42px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
}

.section_title h1
{
	font-size: 36px;
	color: #1f2128;
	font-weight: 500;
}
.section_title h1 span
{
	font-weight: 600;
}
.section_title.light h1
{
	color: #FFFFFF;
}

.Loading{
	text-align: center;
}

.Loading img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}

.expiredEmail, .InvalidToken, .VerifiedUser{
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.expiredEmail_img img, .InvalidToken_img img, .image img{
	height: auto;
	width: 350px;
	margin-top: 100px;
}

.expiredEmail_txt, .InvalidToken_txt, .text{
	color: #111;
	font-size: 20px;
	text-align: center;
}

.requestNewEmail div img.active{
	height: auto;
	width: 20px;
	margin-right: 10px;
}

.requestNewEmail div img.noactive{
	height: auto;
	width: 20px;
	margin-right: 10px;
	display: none;
}

.requestNewEmail div{
	background-color: #ff3500;
	padding: 10px;
	color: #fff;
	margin-top: 10px;
	width: fit-content;
	border-radius: 20px;
}

.requestNewEmail div:hover{
	background-color: #2e3038;
	transition: 0.5s;
	cursor: pointer;
}

.emailNotSent{
	display: none;
}

.emailSent{
	width: 20px;
	margin-right: 10px;
}