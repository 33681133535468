.Error{
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
}

.image img{
	height: auto;
	width: 500px;
	margin-top: 26px;
}

.backHome a{
	background-color: #ff3500;
	color: #fff;
	border-radius: 20px;
	padding: 10px;
}

.backHome a:hover{
	background-color: #2e3038;
	transition: 0.5s;
}

.body{
	overflow: hidden;
}
