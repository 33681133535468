.body{
	overflow: hidden;
}

.form > form{
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 250px auto 0;
}


.signUpForm{
	display: flex;
	flex-direction: column;
	width: 60%;
}

.signUpForm span{
	font-weight: normal;
	color: #999;
	font-size: 12px;
}

.signUpForm input{
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 2px;
	height: 45px;
	padding: 0 15px;
	font-size: 16px;
	outline: none;
}

.signUpForm button{
	background-color: #ff3500;
	color: #ddd;
	font-size: 16px;
	height: 45px;
	outline: none;
	border: none;
	border-radius: 2px;
}

.signUpForm button:hover{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
	transition: 0.5s;
}

.expiredEmail, .InvalidToken, .VerifiedUser{
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.expiredEmail_img img, .InvalidToken_img img, .image img{
	height: auto;
	width: 350px;
	margin-top: 100px;
}

.expiredEmail_txt, .InvalidToken_txt, .text{
	color: #111;
	font-size: 20px;
	text-align: center;
}


