.header
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	z-index: 100;
}

.header.scrolled
{
	background: rgba(0,0,0,0.63);
}
.header_content
{
	width: 100%;
	height: 86px;
}
.header.scrolled .header_content
{
	height: 70px;
}
.logo
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 33px;
	height: 51px;
	z-index: 1;
}
.logo a
{
	display: block;
	width: 100%;
	height: 100%;
	font-size: 30px;
	font-weight: 500;
	color: #FFFFFF;
}
.logo a span
{
	font-weight: 100;
	margin-right: 5px;
}

.signUp{
	background-color: #ff3000;
}

.signUp:hover{
	background-color: #1f2128; 
}
.logo a img
{
	vertical-align: top;
	margin-left: 5px;
}
.main_nav ul li:not(:last-of-type)
{
	margin-right: 40px;
}
.main_nav ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
}
.main_nav ul li a:hover
{
	color: #ff3000;
}
.main_nav ul li a.active
{
	color: #ff3000;
}
.header_right
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 27px;
}
.submit
{
	height: 32px;
	border-radius: 16px;
	margin-right: 19px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.submit a
{
	display: block;
	height: 100%;
	line-height: 30px;
	font-size: 14px;
	font-weight: 500;
	color: #FFFFFF;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
}

.signUp{
	color: #FFFFFF;
	cursor: pointer;
}
.submit:hover
{
	border-color: #ff3000;
}
.submit:hover a
{
	color: #ff3000;
}
.hamburger
{
	display: none;
	margin-left: 20px;
	cursor: pointer;
}
.hamburger i
{
	font-size: 28px;
	color: #FFFFFF;
}

.menu
{
	position: fixed;
	left: 0;
	top: -100vh;
	width: 100vw;
	height: 100vh;
	padding-right: 70px;
	padding-top: 200px;
	background: #1f2128;
	z-index: 99;
	-webkit-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-moz-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-ms-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	-o-transition: all 800ms cubic-bezier(.88,.31,.65,.91);
	transition: all 800ms cubic-bezier(.88,.31,.65,.91);
}
.menu.active
{
	top: 0;
}
.menu_content
{
	width: 100%;
	height: 100%;
}
.menu_nav_list li
{
	-webkit-transform: translateY(-50px);
	-moz-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	-o-transform: translateY(-50px);
	transform: translateY(-50px);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}
.menu_nav_list li:first-child
{
	transition-delay: 400ms;
}
.menu_nav_list li:nth-child(2)
{
	transition-delay: 500ms;
}
.menu_nav_list li:nth-child(3)
{
	transition-delay: 600ms;
}
.menu_nav_list li:nth-child(4)
{
	transition-delay: 700ms;
}
.menu_nav_list li:nth-child(5)
{
	transition-delay: 800ms;
}
.menu_nav_list li:nth-child(6)
{
	transition-delay: 900ms;
}
.menu_nav_list li:nth-child(7)
{
	transition-delay: 1000ms;
}
.menu_nav_list li:nth-child(8)
{
	transition-delay: 1100ms;
}
.menu_nav_list li:nth-child(9)
{
	transition-delay: 1200ms;
}
.menu.active .menu_nav_list li
{
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
	visibility: visible;
	opacity: 1;
}
.menu_nav_list li a
{
	position: relative;
	font-size: 40px;
	color: #FFFFFF;
	font-weight: 400;
	line-height: 1.3;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.menu_nav_list li a:hover
{
	color: #ff3500;
}
.menu_nav_list li a.active
{
	color: #ff3500;
}
.menu_extra
{
	position: absolute;
	left: 0;
	bottom: 15px;
	width: 100%;
}
.menu_submit, .menu_submit2
{
	display: none;
	margin-bottom: 20px;
}
.menu_submit a
{
	white-space: nowrap;
	line-height: 1.1;
	font-size: 12px;
	font-weight: 400;
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_submit a:hover
{
	color: #ff3500;
}
.menu .social
{
	display: none;
}
.menu .social ul li:not(:first-child)
{
	margin-left: 8px;
}

@media only screen and (max-width: 1600px)
{
	.header_content
	{
		padding-left: 200px;
	}
}

@media only screen and (max-width: 1199px)
{
	.main_nav
	{
		display: none;
	}
	.hamburger
	{
		display: block;
	}
}

/*CSS OF THE FORMS IN THE MODAL (SIGNUP, LOGIN)*/

.signUpForm{
	display: flex;
	flex-direction: column;
}

.signUpForm span{
	font-weight: normal;
	color: #999;
	font-size: 12px;
}

.signUpForm input{
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 2px;
	height: 45px;
	padding: 0 15px;
	font-size: 16px;
	outline: none;
}

.signUpForm button{
	background-color: #ff3500;
	color: #ddd;
	font-size: 16px;
	height: 45px;
	outline: none;
	border: none;
	border-radius: 2px;
}

.signUpForm button:hover{
	background: #2e3038;
	box-shadow: 0px 10px 55px rgba(0,0,0,0.55);
	transition: 0.5s;
}

#errorMessage, #errorMessage2, #errorMessage3{
	background-color: #e81a1a3c;
	padding: 10px;
	width: 100%;
	display: none;
	color: #ff0000;
}

#errorMessage.sucess{
	background-color: #43e81a3c;
	padding: 10px;
	width: 100%;
	display: none;
	color: #00ff2a;
}

.d{
	background-color: transparent;
	border: none;
}

.emailConfirmation, .image{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.emailConfirmation img{
	width: 100%;
	height: auto;
	width: 50%;
}


.LogOutButton{
	background-color: #ff0000;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.LogOutButton:hover{
	background-color: #2e3038;
	transition: 0.2s;
}

.CancelLogOutButton{
	background-color: #bababa;
	color: #fff;
	padding: 10px;
	border-radius: 10px;
	width: 80px;
}

.CancelLogOutButton:hover{
	background-color: #a8a8a8;
}

.Loading{
	text-align: center;
}

.Loading img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}

@media only screen and (max-width: 991px)
{
	.submit, .userS
	{
		display: none;
	}
	.menu_submit, .menu_submit2
	{
		display: block;
	}
}


.forgot p{
	color: #ff0000;
	margin-top: 10px;
	cursor: pointer;
}

.forgot p:hover{
	text-decoration: underline;
}

.Loading{
	text-align: center;
}

.Loading img {
	height: auto;
	width: 500px;
	margin-top: 26px;
}

.active{
	margin-right: 10px;
}

.noactive{
	display: none;
}